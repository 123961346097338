import { Main } from './Main'
import { navigateToErrorScreen } from './utils/errors'

export class Download extends Main {
  static baseUrl = this.getRoot()

  static async pdfDownload({ addToast }) {
    const url = `${this.baseUrl}/questionnaire/current-session/export/pdf`

    try {
      const data = await fetch(url, {
        headers: {
          Authorization: 'Basic QWRtaW46YWRtaW4=',
          Accept: 'application/octet-stream',
          'Content-Type': 'application/json',
        },
      })

      if (!data.ok) {
        throw new Error('Unable to download.')
      }

      const blob = await data.blob()
      const date = new Date().toISOString()
      const download = `Questionnaire_${date.replace(/[:.]/g, '_')}.pdf`

      // For MS Edge and IE
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, download)
      } else {
        // For other browsers: create a link pointing to the ObjectURL containing the blob.
        const objUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = objUrl
        // link.download = data.header['content-disposition'].split('"')[1]
        link.download = download
        link.click()
        link.remove()

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          URL.revokeObjectURL(objUrl)
        }, 100)
      }
    } catch (err) {
      addToast('error', 'danger', err.message)

      navigateToErrorScreen({
        fetchType: 'pdfDownload',
        status: err.message,
      })
    }
  }
}
