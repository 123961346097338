import { Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'

function Introduction() {
  const { paragraphs = [] } =
    useSelector(({ configuration }) => configuration.sectionConfig?.introduction) || {}
  const htmlText = paragraphs.join('')

  RemoveLoadingHook()

  return <Typography dangerouslySetInnerHTML={{ __html: htmlText }} />
}

export { Introduction }
