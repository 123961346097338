import { Box, Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { PERSON_CONDITIONS } from '../../utils/ConditionTypes'
import { renderContent, subQuestions } from '../../utils/helpers'
import CheckBoxList from '../../widgets/CheckBoxList'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const heartSymptoms = PERSON_CONDITIONS.filter(({ type }) => type === 'heartSymptoms')

const ConditionSwitch = ({ conditionId, personId, label, isChecked }) => {
  const { setPersonConditions, removePersonConditions } = useActions()

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={(e) => {
            e.currentTarget.checked
              ? setPersonConditions({ id: personId, conditionId })
              : removePersonConditions({ id: personId, conditionId })
          }}
        />
      }
      label={label}
    />
  )
}

const HeartSymptoms = ({ config, id: personId }) => {
  const { conditions = [] } = useSelector(({ questionnaire: { persons } }) => ({
    conditions: persons[personId].conditions,
  }))
  const { setPersonConditionNotes } = useActions()

  return (
    <FormControl>
      <FormLabel>Have you experienced any of the following? Please check all that apply.</FormLabel>
      <CheckBoxList>
        {heartSymptoms.map(({ id, label }) => {
          const condition = conditions.find(({ id: _id }) => _id === id)
          const isChecked = condition?.isPresent === 'Y'
          const subConfig = subQuestions({ config, key: 'heart-symptoms' })

          return (
            <Box key={'condition-' + id}>
              <ConditionSwitch
                conditionId={id}
                label={label}
                isChecked={isChecked}
                personId={personId}
              />
              {isChecked &&
                renderContent({
                  renderMap: {
                    heartSymptomsDetails: (
                      <FollowUpQuestions key="heartSymptomsDetails">
                        <TextFieldQuestion
                          label="Please provide details"
                          value={condition?.notes || ''}
                          handleChange={(value) =>
                            setPersonConditionNotes({ id: personId, conditionId: id, value })
                          }
                        />
                      </FollowUpQuestions>
                    ),
                  },
                  config: subConfig,
                })}
            </Box>
          )
        })}
      </CheckBoxList>
    </FormControl>
  )
}

export default HeartSymptoms
