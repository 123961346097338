import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson, renderContent, subQuestions } from '../../utils/helpers'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

export default function RelativeConditions({ personId, personLabel, conditionId, configId }) {
  const { setPersonConditions, setPersonConditionNotes, removePersonConditions } = useActions()
  const condition = useSelector((state) => {
    const conditions = get(state, [...pathToPerson(personId), 'conditions']) || []

    return conditions.find(({ id }) => id === conditionId)
  })
  const config = useSelector((state) =>
    get(state, ['configuration', 'sectionConfig', 'medical-conditions', 'subSectionConfig']),
  )
  const subConfig = subQuestions({
    config: config[configId]?.questions || [],
    key: 'relatives-' + configId,
  })

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={!!condition} onChange={handleCheckbox} />}
      />
      {!!condition &&
        renderContent({
          renderMap: {
            conditionsDescribe: (
              <FollowUpQuestions key={'description-' + conditionId}>
                <TextFieldQuestion
                  label="Please describe"
                  value={condition?.notes || ''}
                  handleChange={(value) =>
                    setPersonConditionNotes({ id: personId, conditionId, value })
                  }
                />
              </FollowUpQuestions>
            ),
          },
          config: subConfig,
        })}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    // on checkbox selected
    if (e.target.checked) {
      setPersonConditions({
        id: personId,
        conditionId,
      })

      return
    }
    // on checkbox deselected
    removePersonConditions({
      id: personId,
      conditionId,
    })
  }
}
