import { CircularProgress, Typography } from '@mui/material'
import React from 'react'
import CenteredContainer from './CenteredContainer'

const Spinner = ({ message, ...props }) => (
  <CenteredContainer {...props}>
    <CircularProgress size={60} />
    <Typography variant="h2" color="primary">
      {message}
    </Typography>
  </CenteredContainer>
)

export default Spinner
