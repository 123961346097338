import { FormControl } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import { SubQuestionLabel } from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

export const TobaccoQuestion = ({ label, ...props }) => {
  return (
    <FormControl>
      <SubQuestionLabel>{label}</SubQuestionLabel>
      <IntegerDetails min={0} max={150} label={label} {...props} />
    </FormControl>
  )
}

const Tobacco = ({ id, patient, config }) => {
  const localization = useLocalization()
  const {
    setPersonAvgCigarettes,
    setPersonSmokingYears,
    setPersonSmoking,
    removePersonCancerRiskProperty,
  } = useActions()
  const smoking =
    useSelector((state) => state.questionnaire.persons[id].cancerRiskData?.smoking) || ''
  const avgCigarettes =
    useSelector((state) => state.questionnaire.persons[id].cancerRiskData?.avgCigarettes) || ''
  const smokingYears =
    useSelector((state) => state.questionnaire.persons[id].cancerRiskData?.smokingYears) || ''

  return (
    <>
      <Chooser
        orderSchema={['currently', 'previously', 'no']}
        selectedValue={smoking}
        label={getLabel(localization, paths.TOBACCO_USAGE, patient)}
        onChange={(value) => {
          if (value !== smoking) {
            setPersonSmoking({ id, value })

            avgCigarettes !== undefined &&
              removePersonCancerRiskProperty({ id, type: 'avgCigarettes' })
            smokingYears !== undefined &&
              removePersonCancerRiskProperty({ id, type: 'smokingYears' })
          }
        }}
        patient={patient}
        path={paths.TOBACCO_USAGE}
      />
      {smoking === 'currently' || smoking === 'previously' ? (
        <FollowUpQuestions>
          {renderContent({
            renderMap: {
              tobaccoAmount: (
                <TobaccoQuestion
                  key="ta"
                  label={getLabel(localization, paths.AVERAGE_CIGARETTES_DAY_LABEL, 'patient')}
                  value={avgCigarettes}
                  onChange={(value) => {
                    setPersonAvgCigarettes({ id, value })
                  }}
                />
              ),
            },
            config,
          })}
          {renderContent({
            renderMap: {
              tobaccoYears: (
                <TobaccoQuestion
                  key="ty"
                  label={getLabel(localization, paths.TOBACCO_TOTAL_YEARS_LABEL, 'patient')}
                  value={smokingYears}
                  onChange={(value) => {
                    setPersonSmokingYears({ id, value })
                  }}
                />
              ),
            },
            config,
          })}
        </FollowUpQuestions>
      ) : null}
    </>
  )
}

export default Tobacco
