import {
  getPerson,
  setPersonListObjectProperty,
} from '../../../slices/questionnaireSlice/utils/helpers'

/**
 * Updates a simple property at the 1st level of person.cancerRiskData (ie. weight, ageAtMenarche)
 * @param {WritableDraft<{}>} state redux state
 * @param {PersonAction} action redux action
 * @param {string | boolean | number | null} property specific property
 */
const setPersonCancerRiskProperty = (state, action, type) => {
  const { value } = action.payload
  const person = getPerson(state, action)
  const cancerRiskData = person.cancerRiskData

  if (cancerRiskData) {
    if (typeof value !== 'boolean' && (value === '' || value === undefined)) {
      if (cancerRiskData[type] !== undefined) {
        delete cancerRiskData[type]
      }
    } else {
      cancerRiskData[type] = value
    }
  } else {
    person.cancerRiskData = {
      [type]: value,
    }
  }
}

/**
 * Set a property in person.cancerRiskData.alcoholConsumptionDetails
 * @type {PersonReducer}
 */
const setPersonAlcoholConsumptionDetails = (state, action, type) => {
  const person = getPerson(state, action)
  const { value } = action.payload
  const { alcoholConsumptionDetails = [] } = person.cancerRiskData

  const deleteAlcoholProperties = () => {
    delete alcoholConsumptionDetails[0].drinkingFrequency
    delete alcoholConsumptionDetails[0].drinkAmount
  }

  // When the amount value is falsy value, need to delete weekly and the amount properties.
  if (type === 'drinkAmount' && value === '') {
    if (alcoholConsumptionDetails[0]?.drinkingFrequency) {
      return deleteAlcoholProperties()
    }
  }

  if (type === 'typeOfDrink' && alcoholConsumptionDetails[0]) {
    if (alcoholConsumptionDetails[0].typeOfDrink !== value) {
      // Whenever typeOfDrink value switches, it should be removed to be a default value.
      // should not use return here.
      deleteAlcoholProperties()
    } else {
      // if the same type of alcohol we should not update.
      return
    }
  }

  if (!person.cancerRiskData.alcoholConsumptionDetails) {
    person.cancerRiskData.alcoholConsumptionDetails = []
  }

  person.cancerRiskData.alcoholConsumptionDetails[0] = {
    ...person.cancerRiskData.alcoholConsumptionDetails[0],
    [type]: value,
  }
}

export default {
  /**
   * Set person.cancerRiskData.ashkenaziInheritance: boolean
   * @type {PersonReducer}
   */
  setAshkenaziInheritance(state, action) {
    setPersonCancerRiskProperty(state, action, 'ashkenaziInheritance')
  },
  /**
   * Set person.cancerRiskData.weight: number
   * @type {PersonReducer}
   */
  setPersonWeight(state, action) {
    setPersonCancerRiskProperty(state, action, 'weight')
  },
  /**
   * Set person.cancerRiskData.height: number
   * @type {PersonReducer}
   */
  setPersonHeight(state, action) {
    setPersonCancerRiskProperty(state, action, 'height')
  },
  /**
   * Set person.cancerRiskData.ageAtMenarche: number
   * @type {PersonReducer}
   */
  setPersonAgeAtMenarche(state, action) {
    setPersonCancerRiskProperty(state, action, 'ageAtMenarche')
  },
  /**
   * Set person.cancerRiskData.oralContraceptiveUsage: string
   * @type {PersonReducer}
   */
  setPersonOralContraceptiveUsage(state, action) {
    setPersonCancerRiskProperty(state, action, 'oralContraceptiveUsage')
  },
  /**
   * Set person.cancerRiskData.menopauseStatus: string
   * @type {PersonReducer}
   */
  setPersonMenopauseStatus(state, action) {
    setPersonCancerRiskProperty(state, action, 'menopauseStatus')
  },
  /**
   * Set person.cancerRiskData.ageAtMenopause: number
   * @type {PersonReducer}
   */
  setPersonAgeAtMenopause(state, action) {
    setPersonCancerRiskProperty(state, action, 'ageAtMenopause')
  },
  /**
   * Set person.cancerRiskData.hrtUsage: string
   * @type {PersonReducer}
   */
  setPersonHrtUsage: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'hrtUsage')
  },
  /**
   * Set person.cancerRiskData.hrtType: string
   * @type {PersonReducer}
   */
  setPersonHrtType: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'hrtType')
  },
  /**
   * Set person.cancerRiskData.hrtLengthOfUse: number
   * @type {PersonReducer}
   */
  setPersonHrtLengthOfUse: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'hrtLengthOfUse')
  },
  /**
   * Set person.cancerRiskData.previousBreastBiopsies: string
   * @type {PersonReducer}
   */
  setPersonPreviousBreastBiopsies: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'previousBreastBiopsies')
  },
  /**
   * Set person.cancerRiskData.breastBiopsyResult: number
   * @type {PersonReducer}
   */
  setPersonBreastBiopsyResult: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'breastBiopsyResult')
  },
  /**
   * Set person.cancerRiskData.consumesAlcohol: boolean
   * @type {PersonReducer}
   */
  setPersonConsumesAlcohol: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'consumesAlcohol')
  },
  /**
   * Set person.cancerRiskData.smoking: string
   * @type {PersonReducer}
   */
  setPersonSmoking: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'smoking')
  },
  /**
   * Set person.cancerRiskData.avgCigarettes: number
   * @type {PersonReducer}
   */
  setPersonAvgCigarettes: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'avgCigarettes')
  },
  /**
   * Set person.cancerRiskData.smokingYears: number
   * @type {PersonReducer}
   */
  setPersonSmokingYears: (state, action) => {
    setPersonCancerRiskProperty(state, action, 'smokingYears')
  },
  /**
   * Set a property in person.cancerRiskData.alcoholConsumptionDetails
   * @type {PersonReducer}
   */
  setPersonTypeOfDrink: (state, action) => {
    setPersonAlcoholConsumptionDetails(state, action, 'typeOfDrink')
  },
  /**
   * Set a property in person.cancerRiskData.alcoholConsumptionDetails
   * @type {PersonReducer}
   */
  setPersonDrinkingFrequency: (state, action) => {
    setPersonAlcoholConsumptionDetails(state, action, 'drinkingFrequency')
  },
  /**
   * Set a property in person.cancerRiskData.alcoholConsumptionDetails
   * @type {PersonReducer}
   */
  setPersonDrinkAmount: (state, action) => {
    setPersonAlcoholConsumptionDetails(state, action, 'drinkAmount')
  },
  /**
   * Remove person cancerRisk property (ie. weight, height, ageAtMenarche)
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string }}
   * )}
   */
  removePersonCancerRiskProperty: (state, action) => {
    const { type } = action.payload
    const person = getPerson(state, action)

    delete person.cancerRiskData[type]
  },
  /**
   * Add an object to list in person.cancerRiskData.extendedCancerRisk
   * @type {(state: WritableDraft<{}>, action: { payload: {
   *  id: string, type: string, value: string
   * }})}
   */
  setPersonExtendedCancerRisk: (state, action) => {
    const person = getPerson(state, action)
    const { type, value } = action.payload
    const newExtendedCancerRisk = {
      extendedCancerRiskDataType: type,
      isPresent: 'Y',
      value,
    }
    const extendedCancerRisk = person.cancerRiskData?.extendedCancerRiskDataList

    // no existing list
    if (!extendedCancerRisk) {
      person.cancerRiskData = {
        ...person.cancerRiskData,
        extendedCancerRiskDataList: [newExtendedCancerRisk],
      }

      return
    }

    // modify existing property
    const index = extendedCancerRisk.findIndex((item) => item.extendedCancerRiskDataType === type)
    if (index > -1) {
      person.cancerRiskData.extendedCancerRiskDataList[index].value = value
    } else {
      // add new object to existing list
      person.cancerRiskData.extendedCancerRiskDataList = [
        ...extendedCancerRisk,
        newExtendedCancerRisk,
      ]
    }
  },
  /**
   * Remove an object in list in person.cancerRiskData.extendedCancerRisk
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  removePersonExtendedCancerRisk: (state, action) => {
    const person = getPerson(state, action)
    const { type } = action.payload
    const extendedCancerRisk = person.cancerRiskData?.extendedCancerRiskDataList

    if (!extendedCancerRisk) return

    person.cancerRiskData.extendedCancerRiskDataList = extendedCancerRisk.filter(
      (item) => item.extendedCancerRiskDataType !== type,
    )
  },
  /**
   * Add an object to list in person.cancerRiskData.geneTests
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  setPersonGeneTests: (state, action) => {
    const { type } = action.payload
    const person = getPerson(state, action)

    const newGeneTest = {
      nameOfGeneTest: type,
      geneTestResult: 'positive',
    }

    const geneTests = person.cancerRiskData?.geneTests

    if (!geneTests) {
      // add list and new property
      person.cancerRiskData = {
        ...person.cancerRiskData,
        geneTests: [newGeneTest],
      }
    } else {
      person.cancerRiskData.geneTests = [...geneTests, newGeneTest]
    }
  },
  /**
   * Remove an object from list in person.cancerRiskData.geneTests
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  removePersonGeneTests: (state, action) => {
    const { type } = action.payload
    const person = getPerson(state, action)
    const geneTests = person.cancerRiskData.geneTests

    if (!geneTests) {
      throw new Error('Unable to find Gene Test.')
    }

    if (geneTests.length === 1) {
      delete person.cancerRiskData.geneTests

      return
    }

    person.cancerRiskData.geneTests = geneTests.filter(
      ({ nameOfGeneTest }) => nameOfGeneTest !== type,
    )
  },
  /**
   * Set property to list item type: 'prostateBiopsy'
   * ie. person.diagnosticTests[]: { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: string }}
   * )}
   */
  setPersonProstateBiopsy: (state, action) => {
    setPersonListObjectProperty(state, action, 'prostateBiopsy', 'diagnosticTests')
  },
}
