import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import kebabCase from 'lodash/kebabCase'
import React from 'react'
import { useSelector } from 'react-redux'
import { getLabel, getOptions } from '../utils/helpers'
import { SubQuestionLabel } from './FormLabel'

function Chooser({
  label,
  onChange,
  disabled,
  selectedValue,
  orderSchema,
  path,
  patient,
  children,
  options = undefined,
  subQuestion = false,
}) {
  const localization = useSelector(({ localization }) => localization)
  const questionLabel = label || getLabel(localization, path)
  const optionLabels = options ?? getOptions(localization, path, orderSchema, patient)
  const LabelComponent = subQuestion ? SubQuestionLabel : FormLabel

  return (
    <FormControl>
      <LabelComponent id={kebabCase(questionLabel) + '-label'}>{questionLabel}</LabelComponent>
      {children}
      <RadioGroup aria-labelledby={kebabCase(questionLabel) + '-label'} row>
        {optionLabels.map(([value, label], index) => {
          const isChecked = selectedValue === value
          const isDisabled = Array.isArray(disabled) ? disabled[index] : disabled

          return (
            <FormControlLabel
              key={'radio-btn-' + kebabCase(value)}
              label={
                isDisabled ? (
                  label
                ) : (
                  <Typography
                    color={isChecked ? 'primary' : null}
                    fontWeight={isChecked ? 500 : null}
                  >
                    {label}
                  </Typography>
                )
              }
              value={value}
              checked={isChecked}
              onChange={() => onChange(value)}
              control={<Radio />}
              disabled={isDisabled}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default Chooser
