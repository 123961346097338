import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent } from '../../utils/helpers'
import { PersonDiagnosticTests, PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import ConditionalSection from '../../widgets/ConditionalSection'

const ProstateBiopsy = ({ id, config }) => {
  const { setPersonProstateBiopsy } = useActions()
  const localization = useLocalization()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex']))
  const prostateBiopsy =
    useSelector((state) => {
      const diagnosticTests = get(state, [...pathToPerson(id), 'diagnosticTests']) || []

      return diagnosticTests.find(({ type }) => type === PersonDiagnosticTests.PROSTATE_BIOPSY)
    }) || {}

  if (sex !== 'M') return null

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => {
        if (prostateBiopsy.result) {
          setPersonProstateBiopsy({ id, type: 'result', value: '' })
        }
      }}
      label={getLabel(localization, PersonFieldPaths.PROSTATE_BIOPSY_LABEL, 'patient')}
      conditionState={prostateBiopsy.isPerformed || ''}
      onConditionStateChange={(value) => {
        if (!prostateBiopsy.type) {
          setPersonProstateBiopsy({ id, type: 'type', value: 'prostateBiopsy' })
        }
        if (prostateBiopsy.isPerformed !== value) {
          setPersonProstateBiopsy({ id, type: 'isPerformed', value })
        }
      }}
    >
      {renderContent({
        renderMap: {
          prostateBiopsyResult: (
            <Chooser
              key="pbr"
              label={getLabel(localization, PersonFieldPaths.DIAGNOSTIC_RESULT_LABEL, 'patient')}
              onChange={(value) => {
                if (prostateBiopsy.result !== value) {
                  setPersonProstateBiopsy({ id, type: 'result', value })
                }
              }}
              selectedValue={prostateBiopsy.result || ''}
              orderSchema={['normal', 'abnormal', 'noResult']}
              path={PersonFieldPaths.DIAGNOSTIC_TEST_RESULT}
              patient="patient"
              subQuestion
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default ProstateBiopsy
