import { Container } from '@mui/material'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { useActions } from '../hooks/useActions'
import Context from '../utils/context/Context'
import DialogWindow from './DialogWindow'
import InternalCard from './InternalCard'

const MultiPersonCards = ({ personIds, entryRenderer, ...props }) => {
  const { setLoading } = useContext(Context)
  const { removePerson } = useActions()

  const [state, setState] = useState({
    isRemoveAlertOpen: false,
    personIdToRemove: null,
  })

  const removePersonEntry = (personId) => {
    setState({
      personIdToRemove: personId,
      isRemoveAlertOpen: true,
    })
  }

  const confirmRemovePersonEntry = () => {
    removePerson({ personId: state.personIdToRemove })
    setLoading((prevState) => ({
      ...prevState,
      dialog: false,
    }))
    setState({
      isRemoveAlertOpen: false,
      personIdToRemove: null,
    })
  }

  const handleClose = () => {
    setState({
      isRemoveAlertOpen: false,
      personIdToRemove: null,
    })
  }

  return (
    <Container {...props}>
      {personIds.map((personId) => {
        return (
          <InternalCard
            key={personId}
            id={personId}
            removePersonEntry={_.partial(removePersonEntry, personId)}
          >
            {entryRenderer(personId)}
          </InternalCard>
        )
      })}
      <DialogWindow
        open={state.isRemoveAlertOpen}
        onClose={handleClose}
        title="Remove Person"
        text="Are you sure you want to remove this person and all their details?"
        color="warning"
        handleConfirm={confirmRemovePersonEntry}
      />
    </Container>
  )
}

export default MultiPersonCards
