import _ from 'lodash'
import uuidv4 from 'uuid/v4'

import {
  PersonFieldPaths,
  QuestionnaireStateManager,
  StateFieldPaths,
} from './QuestionnaireStateManager.js'

class FamilyHelper {
  constructor(state) {
    this.persons = state[StateFieldPaths.PEOPLE]
    this.relationships = state[StateFieldPaths.RELATIONSHIPS]
  }

  /** General */
  getPerson(personId) {
    if (_.isObject(personId)) {
      personId = personId.id
    }

    return new FamilyHelperPerson(personId, this)
  }

  addPerson(relationshipToProband, sex, personId) {
    // TODO: remove dependency on questionnaireStateManager
    const newPerson = QuestionnaireStateManager.getNewPerson(personId)
    _.set(newPerson, PersonFieldPaths.SEX, sex)
    _.set(newPerson, PersonFieldPaths.RELATIONSHIP_TO_PROBAND, relationshipToProband)
    this.persons[newPerson.id] = newPerson

    return this.getPerson(newPerson.id)
  }

  addRelationship(srcId, targetId, type, props) {
    const newRel = FamilyHelper.getNewRelationship()
    _.set(newRel, RelationshipPaths.SOURCE, srcId)
    _.set(newRel, RelationshipPaths.TARGET, targetId)
    _.set(newRel, RelationshipPaths.TYPE, type)
    if (props) {
      _.set(newRel, RelationshipPaths.PROPERTIES, props)
    }

    this.relationships.push(newRel)

    return newRel
  }

  removeRelationship(srcId, targetId, type) {
    _.remove(
      this.relationships,
      (rel) =>
        _.get(rel, RelationshipPaths.SOURCE) === srcId &&
        _.get(rel, RelationshipPaths.TARGET) === targetId &&
        _.get(rel, RelationshipPaths.TYPE) === type,
    )
  }

  removePerson(personId) {
    delete this.persons[personId]
    _.pullAll(this.relationships, this.getRelationships(personId))
  }

  getRelationships(personId) {
    return _.filter(
      this.relationships,
      (relationship) =>
        _.get(relationship, RelationshipPaths.SOURCE) === personId ||
        _.get(relationship, RelationshipPaths.TARGET) === personId,
    )
  }

  getRelationship(relationshipId) {
    return this.relationships[relationshipId]
  }

  doesPersonHaveRelationships(personId) {
    return this.getRelationships(personId).length > 0
  }

  doesPersonExist(personId) {
    return _.has(this.persons, personId)
  }

  getIdsOfPersonsWithProperty(propertyPath, propertyVal) {
    return _.chain(this.persons)
      .values()
      .filter((person) =>
        _.isUndefined(propertyVal)
          ? _.get(person, propertyPath)
          : _.get(person, propertyPath) === propertyVal,
      )
      .map('id')
      .value()
  }

  getAllRelationships() {
    return this.relationships
  }

  getAllPersons() {
    return this.persons
  }

  /** Parents/Children */

  resolveParent(personId, sex, shouldCreate, title) {
    const matchingRelationships = _.filter(
      this.relationships,
      (relationship) => relationship.target === personId && relationship.type === 'parent',
    )

    const matchingPersons = _.map(matchingRelationships, (relationship) =>
      this.getPerson(relationship.source),
    )

    let matchingPerson = _.find(
      matchingPersons,
      (person) => person.getDetailsObj()[PersonFieldPaths.SEX] === sex,
    )

    if (!matchingPerson && shouldCreate) {
      matchingPerson = this.addPerson(title, sex)
      this.addParentToPerson(personId, matchingPerson.id)
    } else if (!matchingPerson) {
      return new FamilyHelperPerson()
    }

    return new FamilyHelperPerson(matchingPerson.id, this)
  }

  // similar to resolveParent but returns an array of parents with the provided sex.
  // Useful in the case when a child could have two parents of Unknown sex.
  resolveParents(personId, sex, shouldCreate, title) {
    const matchingRelationships = _.filter(
      this.relationships,
      (relationship) => relationship.target === personId && relationship.type === 'parent',
    )

    const matchingPersons = _.map(matchingRelationships, (relationship) =>
      this.getPerson(relationship.source),
    ).filter((person) => person.getDetailsObj()[PersonFieldPaths.SEX] === sex)

    if (matchingPersons.length === 0 && shouldCreate) {
      const matchingPerson = this.addPerson(title, sex)
      matchingPersons.push(matchingPerson)
      this.addParentToPerson(personId, matchingPerson.id)
    } else if (matchingPersons.length === 0) {
      return [new FamilyHelperPerson()]
    }

    return matchingPersons
  }

  addParentToPerson(personId, parentId, relProps) {
    this.addRelationship(parentId, personId, RelationshipTypes.PARENT, relProps)
  }

  getChildren(personId, childSex = undefined) {
    const matchingRelatives = _.filter(
      this.relationships,
      (rel) =>
        _.get(rel, RelationshipPaths.SOURCE) === personId &&
        _.get(rel, RelationshipPaths.TYPE) === FamilyHelper.RelationshipTypes.PARENT,
    )

    let children = _.map(matchingRelatives, (rel) =>
      this.getPerson(_.get(rel, RelationshipPaths.TARGET)),
    )

    if (childSex) {
      children = _.filter(
        children,
        (child) => _.get(child.getDetailsObj(), PersonFieldPaths.SEX) === childSex,
      )
    }

    return children
  }

  getChildrensParentsIds(personId) {
    const childrensParents = this.getChildren(personId).map((child) => [
      child.resolveParent('M').id,
      child.resolveParent('F').id,
      ...child.resolveParents('U').map((person) => person.id),
    ])

    const allOtherParents = _.uniq(
      _.flatten(childrensParents).filter((id) => id && id !== personId),
    )

    return allOtherParents
  }

  setChildCount(personId, childSex, newCount, targetPersonArray) {
    const totalChildren = this.getChildren(personId).length
    const curChildren = this.getChildren(personId, childSex)
    const curCount = curChildren.length
    let otherParentId = this.getChildrensParentsIds(personId)[0]

    if (totalChildren === 0 && newCount > curCount) {
      otherParentId = this.addPerson(
        translateRelationshipArray(targetPersonArray, 'partner'),
        'U',
      ).getDetailsObj().id
    }

    if (newCount > curCount) {
      for (let i = 0; i < newCount - curCount; i++) {
        const newChild = this.addPerson(
          translateRelationshipArray(targetPersonArray, 'child'),
          childSex,
        )

        this.addParentToPerson(newChild.id, personId)

        // assume that all children are from the same other parent
        this.addParentToPerson(newChild.id, otherParentId)
      }
    } else if (newCount < curCount) {
      const emptyChildren = _.filter(curChildren, (child) =>
        QuestionnaireStateManager.isPersonEmpty(child.getDetailsObj()),
      )

      const toDelete = _.take(
        _.values(emptyChildren),
        Math.min(emptyChildren.length, curCount - newCount),
      )

      _.forEach(toDelete, (child) => this.removePerson(child.id))

      // if the person has no more children
      if (totalChildren + (newCount - curCount) === 0) {
        this.removePerson(otherParentId)
      }
    }
  }

  getSharedParentIds(person1Id, person2Id) {
    const getParentIds = (personId) => {
      const parentRelatives = this.relationships.filter((rel) => {
        return (
          _.get(rel, RelationshipPaths.TARGET) === personId &&
          _.get(rel, RelationshipPaths.TYPE) === 'parent'
        )
      })

      return _.map(parentRelatives, (rel) => _.get(rel, RelationshipPaths.SOURCE))
    }

    const person1ParentIds = getParentIds(person1Id)
    const person2ParentIds = getParentIds(person2Id)

    return _.intersection(person1ParentIds, person2ParentIds)
  }

  getAllParentIds() {
    return _.chain(this.relationships)
      .filter((rel) => _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.PARENT)
      .map(RelationshipPaths.SOURCE)
      .uniq()
      .value()
  }

  /** Siblings */

  getSiblings(personId, sex, fullOrHalf) {
    const person = this.getPerson(personId)
    const parentIds = []
    const mother = person.resolveParent('F')

    mother.id && parentIds.push(mother.id)

    const father = person.resolveParent('M')

    father.id && parentIds.push(father.id)

    const relationshipsIncludingPerson = this.relationships.filter((relationship) => {
      return (
        _.get(relationship, RelationshipPaths.TARGET) !== personId &&
        _.get(relationship, RelationshipPaths.TYPE) === 'parent'
      )
    })

    const matchingRelationships = relationshipsIncludingPerson.filter((relationship) => {
      return _.includes(parentIds, _.get(relationship, RelationshipPaths.SOURCE))
    })

    let personMatches = {}

    _.each(matchingRelationships, (relationship) => {
      let personMatch = personMatches[_.get(relationship, RelationshipPaths.TARGET)]
      if (!personMatch) {
        personMatch = {
          person: this.getPerson(_.get(relationship, RelationshipPaths.TARGET)),
          sharedParentIds: [],
          areParentsAmbiguous: false,
        }
        personMatches[_.get(relationship, RelationshipPaths.TARGET)] = personMatch
      }

      const isAmbiguous = _.some(relationship[RelationshipPaths.PROPERTIES], {
        type: FamilyHelper.RelationshipPropertyTypes.IS_AMBIGUOUS,
        isPresent: 'Y',
      })

      if (isAmbiguous) {
        personMatch.areParentsAmbiguous = true
      }

      personMatch.sharedParentIds.push(_.get(relationship, RelationshipPaths.SOURCE))
    })

    if (sex) {
      personMatches = _.pickBy(
        personMatches,
        (personMatch) => _.get(personMatch.person.getDetailsObj(), PersonFieldPaths.SEX) === sex,
      )
    }

    if (fullOrHalf) {
      personMatches = _.pickBy(personMatches, (personMatch) => {
        const isHalfSibling = FamilyHelper.isHalfSibling(personMatch)
        const returnHalfSiblings = fullOrHalf === 'half'

        return (isHalfSibling && returnHalfSiblings) || (!isHalfSibling && !returnHalfSiblings)
      })
    }

    // sort person matches evaluating sex field, half sibling condition and the key
    const sortedPersonMatchesKeys = _.sortBy(Object.keys(personMatches), (personMatchKey) => {
      const isHalfSibling = FamilyHelper.isHalfSibling(personMatches[personMatchKey])
      const sex = _.get(personMatches[personMatchKey].person.getDetailsObj(), PersonFieldPaths.SEX)

      let position = '4'
      if (sex === 'F' && !isHalfSibling) {
        position = '1'
      } else if (sex === 'M' && !isHalfSibling) {
        position = '2'
      } else if (sex === 'F' && isHalfSibling) {
        position = '3'
      }

      return position + personMatchKey
    })

    const sortedPersonMatches = {}

    _.each(
      sortedPersonMatchesKeys,
      (personMatchKey) => (sortedPersonMatches[personMatchKey] = personMatches[personMatchKey]),
    )

    return sortedPersonMatches
  }

  getSiblingCount(personId, siblingSex, fullOrHalf) {
    const curSiblings = this.getSiblings(personId, siblingSex, fullOrHalf)

    return _.keys(curSiblings).length
  }

  setSiblingCount(personId, siblingSex, fullOrHalf, targetPersonArray) {
    const siblingTranslation = { M: 'brother', F: 'sister', U: 'sibling' }

    // Make sure the person has parents before we add siblings
    this.resolveParent(personId, 'F', true, translateRelationshipArray(targetPersonArray, 'mother'))
    this.resolveParent(personId, 'M', true, translateRelationshipArray(targetPersonArray, 'father'))

    const parentRelationships = _.filter(
      this.relationships,
      (relationship) => relationship.target === personId && relationship.type === 'parent',
    )

    const title = translateRelationshipArray(
      targetPersonArray,
      `${fullOrHalf === 'half' ? 'half-' : ''}${siblingTranslation[siblingSex || 'U']}`,
    )

    this.addSibling(siblingSex, parentRelationships, fullOrHalf === 'half', title)
  }

  addSibling(sex, parentRelationships, areParentsAmbiguous, title) {
    const newPerson = this.addPerson(title, sex)
    const newRelative = _.map(parentRelationships, (rel) => {
      const relClone = _.cloneDeep(rel)
      const newRel = FamilyHelper.getNewRelationship()

      _.set(newRel, FamilyHelper.RelationshipPaths.TARGET, newPerson.id)

      if (areParentsAmbiguous) {
        const isAmbiguousProperty = {
          type: FamilyHelper.RelationshipPropertyTypes.IS_AMBIGUOUS,
          isPresent: 'Y',
        }
        newRel[FamilyHelper.RelationshipPaths.PROPERTIES].push(isAmbiguousProperty)
      }

      _.merge(relClone, newRel)

      return relClone
    })

    this.relationships.push(...newRelative)
  }

  getHalfSiblingSharedParentType(person1Id, person2Id) {
    const sharedParentIds = this.getSharedParentIds(person1Id, person2Id)

    // TODO: need to redefine this logic with try - catch - finally
    if (sharedParentIds.length === 0) {
      // console.error('Half sibling does not share any parents')
    } else if (sharedParentIds.length === 1) {
      return _.get(this.getPerson(sharedParentIds[0]).getDetailsObj(), PersonFieldPaths.SEX)
    } else {
      // either the parents are ambiguous, or this is a full sibling
      return null
    }
  }

  setHalfSiblingSharedParentType(personId, siblingId, sharedParentSex) {
    const sharedParentIds = this.getSharedParentIds(personId, siblingId)

    const sharedParentRelatives = _.filter(
      this.relationships,
      (rel) =>
        _.includes(sharedParentIds, _.get(rel, RelationshipPaths.SOURCE)) &&
        _.get(rel, RelationshipPaths.TARGET) === personId,
    )

    // TODO: need to redefine this logic with try - catch - finally
    if (sharedParentIds.length === 0) {
      // console.error('Half sibling does not share any parents')
    } else if (sharedParentIds.length === 1) {
      const curSharedParentSex = _.get(
        this.getPerson(sharedParentIds[0]).getDetailsObj(),
        PersonFieldPaths.SEX,
      )

      if (curSharedParentSex !== sharedParentSex) {
        const newSharedParentId = this.resolveParent(siblingId, sharedParentSex, false).id
        _.set(sharedParentRelatives[0], RelationshipPaths.SOURCE, newSharedParentId)
      }
    } else if (sharedParentIds.length === 2) {
      // Assuming parents are ambiguous,
      // 1. set relationship with sharedParentSex parent to be non-ambiguous
      const relToKeep = _.find(sharedParentRelatives, (rel) => {
        const parentId = _.get(rel, RelationshipPaths.SOURCE)

        return (
          _.get(this.getPerson(parentId).getDetailsObj(), PersonFieldPaths.SEX) === sharedParentSex
        )
      })

      relToKeep[RelationshipPaths.PROPERTIES] = _.filter(relToKeep[RelationshipPaths.PROPERTIES], {
        type: FamilyHelper.RelationshipPropertyTypes.IS_AMBIGUOUS,
        isPresent: 'Y',
      })

      _.pull(sharedParentRelatives, relToKeep)

      // 2. delete opposite-sex parent relationship
      _.pull(this.relationships, sharedParentRelatives[0])
    }
  }

  setTwinRelationship(siblingId, probandId, relPath) {
    if (this.getTwinRelationships(siblingId, probandId, RelationshipTypes.TWIN)) {
      this.removeRelationship(siblingId, probandId, RelationshipTypes.TWIN)
    }

    if (relPath) {
      const relationshipType = [{ type: relPath, isPresent: 'Y' }]
      this.addRelationship(siblingId, probandId, RelationshipTypes.TWIN, relationshipType)
      // this.addRelationship(siblingId, probandId, RelationshipTypes.TWIN, relPath)
    }
  }

  getTwinRelationships(siblingId, probandId) {
    return _.find(
      this.relationships,
      (rel) =>
        _.get(rel, RelationshipPaths.TARGET) === probandId &&
        _.get(rel, RelationshipPaths.SOURCE) === siblingId &&
        _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.TWIN,
    )
  }

  /** Cousins */
  getFirstCousins(personId, parentSex, personFilter) {
    // 1. Get all first cousins based on structural inferences
    // 1.1. Get person IDs for siblings of requested parent
    const parent = this.resolveParent(personId, parentSex, false)

    let structuralFirstCousins = []

    if (parent.id) {
      const parentSiblingIds = _.keys(this.getSiblings(parent.id))
      // 1.2. Get all children of all siblings from (1.1)
      const structuralRelatives = _.filter(
        this.relationships,
        (rel) =>
          _.includes(parentSiblingIds, _.get(rel, RelationshipPaths.SOURCE)) &&
          _.get(rel, RelationshipPaths.TYPE) === FamilyHelper.RelationshipTypes.PARENT,
      )

      structuralFirstCousins = _.map(structuralRelatives, (rel) =>
        this.getPerson(_.get(rel, RelationshipPaths.TARGET)),
      )
    }

    // 2. Get all first cousins based on direct relationships to the person
    const directRelatives = _.filter(
      this.relationships,
      (rel) =>
        (_.get(rel, RelationshipPaths.SOURCE) === personId ||
          _.get(rel, RelationshipPaths.TARGET) === personId) &&
        _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.COUSIN &&
        _.get(rel, RelationshipPaths.PROPERTIES_DEGREE) === 1,
    )

    const directRelFirstCousins = _.map(directRelatives, (rel) => {
      let key

      if (_.get(rel, RelationshipPaths.SOURCE) === personId) {
        key = RelationshipPaths.TARGET
      } else {
        key = RelationshipPaths.SOURCE
      }

      return this.getPerson(_.get(rel, key))
    })

    // 3. Get the union of the 2 result sets above
    let cousins = _.concat(structuralFirstCousins, directRelFirstCousins)

    // 4. Filter, if necessary
    if (personFilter) {
      cousins = _.filter(cousins, personFilter)
    }

    return cousins
  }

  // TODO: never gets called because there is no situation
  // in which the cousin does not exist when they have info being filled out
  addCousin(probandId, newPersonId) {
    const newPerson = this.addPerson('probandsCousin', undefined, newPersonId)
    const newRel = this.addRelationship(newPerson.id, probandId, RelationshipTypes.COUSIN)
    _.set(newRel, RelationshipPaths.PROPERTIES_DEGREE, 1)

    return newPerson
  }

  /** User-defined relatives */

  getUserDefinedRelatives(targetPersonId, sourcePersonId) {
    return _.chain(this.relationships)
      .filter(
        (rel) =>
          (!targetPersonId || _.get(rel, RelationshipPaths.TARGET) === targetPersonId) &&
          (!sourcePersonId || _.get(rel, RelationshipPaths.sourcePersonId) === sourcePersonId) &&
          _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.USER_DEFINED,
      )
      .map((rel) => {
        return {
          person: this.getPerson(_.get(rel, RelationshipPaths.SOURCE)),
          relation: _.get(rel, RelationshipTypes.PROPERTIES_USER_DEFINITION),
        }
      })
      .keyBy((result) => result.person.id)
      .value()
  }

  addUserDefinedRelative(targetPersonId, newPersonId, targetPersonArray) {
    const newPerson = this.addPerson(
      translateRelationshipArray(targetPersonArray, 'relative'),
      undefined,
      newPersonId,
    )

    this.addRelationship(newPerson.id, targetPersonId, RelationshipTypes.USER_DEFINED)

    return newPerson
  }

  getUserDefinedRelativeRelationship(targetPersonId, sourcePersonId) {
    return _.find(
      this.relationships,
      (rel) =>
        _.get(rel, RelationshipPaths.TARGET) === targetPersonId &&
        _.get(rel, RelationshipPaths.SOURCE) === sourcePersonId &&
        _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.USER_DEFINED,
    )
  }
}

class FamilyHelperPerson {
  constructor(id, family) {
    this.id = id
    this.family = family
  }

  resolveParent(sex, shouldCreate, title) {
    if (!this.id) {
      return new FamilyHelperPerson() // return a null Person
    }

    return this.family.resolveParent(this.id, sex, shouldCreate, title)
  }

  resolveParents(sex, shouldCreate, title) {
    if (!this.id) {
      return new FamilyHelperPerson() // return a null Person
    }

    return this.family.resolveParents(this.id, sex, shouldCreate, title)
  }

  getSiblings(sex, fullOrHalf) {
    if (!this.id) {
      return []
    }

    return this.family.getSiblings(this.id, sex, fullOrHalf)
  }

  getFirstEmptySibling(sex, sharedParentIds) {
    return this.family.getFirstEmptySibling(this.id, sex, sharedParentIds)
  }

  getDetailsObj() {
    return (this.id && this.family.persons[this.id]) || {}
  }

  getSiblingCount(siblingSex, fullOrHalf) {
    this.family.getSiblingCount(this.id, siblingSex, fullOrHalf)
  }

  setSiblingCount(siblingSex, fullOrHalf, newCount) {
    this.family.setSiblingCount(this.id, siblingSex, fullOrHalf, newCount)
  }

  addSibling(sex, sharedParentIds, areParentsAmbiguous) {
    this.family.addSibling(this.id, sex, sharedParentIds, areParentsAmbiguous)
  }
}

FamilyHelper.getNewRelationship = () => {
  return {
    id: uuidv4(),
    properties: [],
  }
}

FamilyHelper.isHalfSibling = (sibling) => {
  return sibling.sharedParentIds.length === 1 || sibling.areParentsAmbiguous
}

FamilyHelper.RelationshipTypes = {
  PARENT: 'parent',
  PARTNER: 'partner',
  PARTNERSHIP: 'partnership',
  TWIN: 'twin',
  IDENTICAL_TWIN: 'monozygoticTwin',
  FRATERNAL_TWIN: 'dizygoticTwin',
  COUSIN: 'cousin',
  USER_DEFINED: 'userDefined',
  UNKNOWN: 'unknown',
}

FamilyHelper.RelationshipPaths = {
  ID: 'id',
  SOURCE: 'source',
  TARGET: 'target',
  TYPE: 'type',
  PROPERTIES: 'properties',
  PROPERTIES_IS_AMBIGUOUS: 'properties.isAmbiguous',
  PROPERTIES_DEGREE: 'properties.degree',
  PROPERTIES_USER_DEFINITION: 'properties.userDefinition',
}

FamilyHelper.RelationshipPropertyTypes = {
  MONOZYGOTIC_TWIN: 'monozygoticTwin',
  DIZYGOTIC_TWIN: 'dizygoticTwin',
  SEPARATED: 'separated',
  CONSANGUINEOUS: 'consanguineous',
  CHILDLESS: 'childless',
  REASON: 'reason',
  IS_AMBIGUOUS: 'isAmbiguous',
  USER_DEFINITION: 'userDefinition',
}

FamilyHelper.RelationshipTranslations = {
  3: {
    partner: ['sPartner'],
    child: ['sChild'],
  },
  2: {
    father: ['Grandfather'],
    mother: ['Grandmother'],
    'half-sibling': ['HalfSibling'],
    sibling: ['Sibling'],
    brother: ['Uncle'],
    sister: ['Aunt'],
    'half-brother': ['HalfUncle'],
    'half-sister': ['HalfAunt'],
    relative: ['Relative'],
  },
  1: {
    father: ['sFather', 'sPaternal'],
    mother: ['sMother', 'sMaternal'],
    'half-sibling': ['sHalfSibling'],
    sibling: ['sSibling'],
    brother: ['sBrother'],
    sister: ['sSister'],
    'half-brother': ['sHalfBrother'],
    'half-sister': ['sHalfSister'],
    partner: ['sPartner'],
    child: ['sChild'],
  },
  0: {
    proband: ['proband'],
  },
}

FamilyHelper.translateRelationshipArray = (arr, newRel) => {
  const label = [...arr, newRel].map((person, i) => {
    const relLabel = RelationshipTranslations[i][person]

    if (i === 0 || i === arr.length) {
      return relLabel[0]
    }

    if (relLabel.length > 1) {
      return relLabel[1]
    } else {
      return relLabel[0]
    }
  })

  return label.join('')
}

const RelationshipPaths = FamilyHelper.RelationshipPaths
const RelationshipTypes = FamilyHelper.RelationshipTypes
const RelationshipTranslations = FamilyHelper.RelationshipTranslations
const translateRelationshipArray = FamilyHelper.translateRelationshipArray

export { FamilyHelper, RelationshipPaths, RelationshipTypes, translateRelationshipArray }
export default FamilyHelper
