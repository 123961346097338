import { createSlice } from '@reduxjs/toolkit'

export const metricSlice = createSlice({
  name: 'localization',
  initialState: { destinationPage: 'Introduction' },
  reducers: {
    setMetrics(_, action) {
      return action.payload
    },
  },
})

const { reducer, actions } = metricSlice
export const { setMetrics } = actions

export default reducer
