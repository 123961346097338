import { createSlice } from '@reduxjs/toolkit'

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState: {},
  reducers: {
    setConfigurationValues(_, action) {
      return action.payload
    },
  },
})

const { reducer, actions } = configurationSlice
export const { setConfigurationValues } = actions

export default reducer
