import { PersonFieldPaths, PersonConditions } from './QuestionnaireStateManager'

export const conditionRelativeQuestions = [
  {
    id: PersonConditions.PANCREATITIS,
    configId: 'pancreatitis',
    title: 'Chronic Pancreatitis',
    propertyPath: PersonFieldPaths.PANCREATITIS_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.PANCREATITIS,
  },
  {
    id: PersonConditions.PITUITARY,
    configId: 'pituitary',
    title: 'Pituitary Tumours',
    propertyPath: PersonFieldPaths.PITUITARY_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.PITUITARY,
  },
  {
    id: PersonConditions.TUMOUR,
    configId: 'tumours',
    title: 'Tumours & Growths',
    propertyPath: PersonFieldPaths.TUMOUR_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.TUMOUR,
  },
  {
    id: PersonConditions.MOLE_NEVI,
    configId: 'moles',
    title: 'Moles & Nevis',
    propertyPath: PersonFieldPaths.MOLE_NEVI_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.MOLE_NEVI,
  },
  {
    id: PersonConditions.POLYPS_REMOVED,
    configId: 'polyps',
    title: 'Polyps Removed',
    propertyPath: PersonFieldPaths.POLYPS_REMOVED_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.POLYPS_REMOVED,
  },
  {
    id: PersonConditions.BLOOD_CLOT,
    configId: 'blood-clot',
    title: 'Blood Clot',
    propertyPath: PersonFieldPaths.BLOOD_CLOT_LABEL,
    propertyType: PersonFieldPaths.CONDITIONS,
    personProperty: PersonConditions.BLOOD_CLOT,
  },
]

export default conditionRelativeQuestions
