import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { renderContent, subQuestions } from '../../utils/helpers'
import DateOfBirth from '../../widgets/DateOfBirth'
import FullName from '../../widgets/FullName'
import LifeStatus from '../../widgets/LifeStatus'
import SexAssignedAtBirth from '../../widgets/SexAssignedAtBirth'

const ChildQuestions = ({ personId, config }) => {
  const localization = useSelector(({ localization }) => localization)

  const renderMap = {
    name: (
      <FullName
        key="fn"
        id={personId}
        localization={localization}
        config={subQuestions({ config, key: 'name' })}
      />
    ),
    sexAtBirth: <SexAssignedAtBirth key="sab" id={personId} />,
    dateOfBirth: <DateOfBirth key="dob" id={personId} />,
    lifeStatus: (
      <LifeStatus
        key="ls"
        personId={personId}
        config={subQuestions({ config, key: 'lifeStatus' })}
      />
    ),
  }
  const content = []

  renderContent({ renderMap, config, content })

  return <Container key={personId}>{content}</Container>
}

export default ChildQuestions
