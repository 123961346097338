import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router-dom'
import { Auth } from '../api/Auth'
import { useActions } from '../hooks/useActions'
import Context from '../utils/context/Context'
import DialogWindow from '../widgets/DialogWindow'

export const SessionContext = createContext({})

// timer until the user sees prompt (20min)
const PRE_PROMPT_TIMEOUT = 1000 * 60 * 20
// timer the prompt gives to the user (2min)
const PROMPT_TIMEOUT = 1000 * 60 * 2
// total time until user is logged out
const SESSION_TIMEOUT = PROMPT_TIMEOUT + PRE_PROMPT_TIMEOUT

const DialogText = () => {
  const [time, setTime] = useState(PROMPT_TIMEOUT / 1000)
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      Your session will expire in <b>{time}</b> seconds. Please click Resume Session if you would
      like to continue working.
    </>
  )
}

const SessionTimeout = ({ children }) => {
  const history = useHistory()
  const { sendStateData } = useActions()
  const {
    authState: { isAuthenticated },
    setTimeoutModal,
    timeoutModal,
    setLoading,
  } = useContext(Context)
  const { activate } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout: SESSION_TIMEOUT,
    promptBeforeIdle: PROMPT_TIMEOUT,
    events: [],
    crossTab: true,
  })

  // This is necessary so we don't re-render children on remaining time updates
  const value = useMemo(() => ({ activate }), [activate])

  return (
    <SessionContext.Provider value={value}>
      {children}
      <DialogWindow
        open={timeoutModal.open && timeoutModal.type === 'warning'}
        onClose={async () => {
          await handleContinue()
        }}
        handleConfirm={async () => {
          await handleContinue()
            // handling the other browser tabs
            .then(() => {
              setLoading((prevState) => ({
                ...prevState,
                dialog: false,
              }))
            })
            .catch(() => {})
        }}
        title="Session expiring"
        text={<DialogText />}
        color="warning"
        primaryBtn={`Resume Session`}
        noSecondary
      />
    </SessionContext.Provider>
  )

  async function handleContinue() {
    await Auth.getSession()
    setTimeoutModal({
      type: '',
      open: false,
    })

    activate()
  }

  async function onIdle() {
    setTimeoutModal(() => ({
      type: '',
      open: false,
    }))

    await sendStateData()
    await Auth.logout()
    await history.push('/user-logout', {
      message: `Your session has expired. To protect your information,
      you have been logged out.`,
    })

    setTimeoutModal(() => ({
      type: 'timedOut',
      open: true,
    }))
  }

  function onPrompt() {
    if (isAuthenticated && !timeoutModal.open) {
      setTimeoutModal({
        type: 'warning',
        open: true,
      })
    }
  }
}

export default SessionTimeout
