import { Typography } from '@mui/material'
import React from 'react'
import RemoveLoadingHook from '../hooks/RemoveLoadingHook'
import InfoDisplay from '../widgets/InfoDisplay'

function Message({ history }) {
  RemoveLoadingHook('Message')

  const { errMessages = [] } = history.location.state || {}

  let specificMessage = null

  if (errMessages.length > 1) {
    specificMessage = (
      <>
        {errMessages.map((err, index) => (
          <Typography variant="body2" key={index}>
            {err}
          </Typography>
        ))}
      </>
    )
  } else if (errMessages.length === 1) {
    specificMessage = <Typography className="errorMessage">{errMessages[0]}</Typography>
  }

  return (
    <InfoDisplay
      title={
        specificMessage
          ? 'Sorry, unable to Load Questionnaire.'
          : 'Sorry, you seem to be in the wrong page. Please, go back.'
      }
      description={
        specificMessage ? 'We cannot load the questionnaire, some possible causes are: ' : null
      }
      alert={specificMessage}
      color="primary"
    />
  )
}

export default Message
