import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

function HeartSpecialist({ id, heartProblems, config }) {
  const { setPersonConditionTreatments } = useActions()
  const localization = useSelector((state) => state.localization)
  const {
    isUnderSpecialistCare = '',
    lastAppointmentDate = '',
    lastAppointmentDetails = '',
  } = heartProblems.treatments?.find(({ type }) => type === 'other') || {}

  const treatmentProps = {
    id,
    conditionId: 'heartProblems',
    treatmentType: 'other',
  }

  return (
    <ConditionalSection
      label="Are you currently under the care or have previously 
      been under the care of a heart specialist?"
      orderSchema={['Y', 'N', 'U']}
      path="yesNoUnknown"
      showWhenCondition="Y"
      conditionState={isUnderSpecialistCare}
      onConditionStateChange={(value) => {
        if (value !== isUnderSpecialistCare) {
          if (value === 'Y') {
            setPersonConditionTreatments({
              ...treatmentProps,
              value: { isUnderSpecialistCare: value },
              isPerformed: value,
            })
          } else {
            setPersonConditionTreatments({
              ...treatmentProps,
              value: {
                isUnderSpecialistCare: value,
                lastAppointmentDetails: '',
                lastAppointmentDate: '',
              },
              isPerformed: value,
            })
          }
        }
      }}
      cleanUpChildQuestions={() => {}}
    >
      {renderContent({
        renderMap: {
          heartSpecialistAppointment: (
            <TextFieldQuestion
              key="lastAppointmentDate"
              label={getLabel(localization, 'treatment.lastAppointmentDate', 'patient')}
              value={lastAppointmentDate}
              handleChange={(value) => {
                setPersonConditionTreatments({
                  ...treatmentProps,
                  value: { lastAppointmentDate: value },
                  isPerformed: 'Y',
                })
              }}
            />
          ),
          heartSpecialistAppointmentDetails: (
            <TextFieldQuestion
              key="lastAppointmentDetails"
              label={getLabel(localization, 'treatment.lastAppointmentDetails', 'patient')}
              value={lastAppointmentDetails}
              handleChange={(value) => {
                setPersonConditionTreatments({
                  ...treatmentProps,
                  value: { lastAppointmentDetails: value },
                  isPerformed: 'Y',
                })
              }}
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default HeartSpecialist
