import {
  getPerson,
  setPersonListObjectProperty,
} from '../../../slices/questionnaireSlice/utils/helpers'

/**
 * Remove entire object from a list
 * ie person.diagnosticTests: [{}, {}, ...]
 * @type {(state: WritableDraft<{}>,
 *  action: { payload: { id: string, type: string }}
 *  listType: string
 * )}
 */
const removePersonSurgeries = (state, action, listType) => {
  const { type } = action.payload
  const person = getPerson(state, action)

  person[listType] = person[listType].filter(({ type: _type }) => _type !== type)
}

export default {
  /**
   * Set new surgery object in list
   * ie. person.surgeries[]: { type: 'hysterectomy' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, surgeryType: string, type: string, value: string }}
   * )}
   */
  setPersonSurgeries(state, action) {
    const { surgeryType } = action.payload
    setPersonListObjectProperty(state, action, surgeryType, 'surgeries')
  },
  /**
   * Remove surgery object with specified type from list
   * ie. person.surgeries[]: { type: 'hysterectomy' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string }}
   * )}
   */
  removePersonSurgeries(state, action) {
    removePersonSurgeries(state, action, 'surgeries')
  },
}
