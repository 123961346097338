import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Container,
  Button,
} from '@mui/material'
import React, { useContext } from 'react'
import { theme } from '../theme'
import Context from '../utils/context/Context'

const DialogWindow = ({
  open,
  onClose,
  title,
  text,
  secondaryBtn = 'Cancel',
  primaryBtn = 'Confirm',
  handleConfirm,
  color = 'primary',
  children,
  noSecondary = false,
  ...props
}) => {
  const { loading, setLoading } = useContext(Context)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle
        sx={{ color: theme.palette[color].main, backgroundColor: theme.palette[color].bg }}
      >
        {title}
      </DialogTitle>
      {/* MUI padding bug DialogContent issue #27851 */}
      <DialogContent sx={{ paddingTop: (theme) => theme.spacing(3) + ' !important' }}>
        <Container>
          <Typography variant="body2">{text}</Typography>
          {children}
        </Container>
      </DialogContent>
      <DialogActions>
        {noSecondary ? null : (
          <Button color={color} onClick={onClose} disabled={loading?.dialog}>
            <span>{secondaryBtn}</span>
          </Button>
        )}
        <LoadingButton
          onClick={async (e) => {
            setLoading((prevState) => ({
              ...prevState,
              dialog: true,
            }))
            await handleConfirm(e)
          }}
          variant="contained"
          color={color}
          autoFocus
          loading={loading?.dialog}
        >
          <span>{primaryBtn}</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DialogWindow
