import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { useState } from 'react'
import Routes from './Routes'
import { theme } from './theme'
import Context from './utils/context/Context'
import { getErrorMessage } from './utils/helpers'

function App() {
  const [demoMode, setDemoMode] = useState(true)
  const [loading, setLoading] = useState({})
  const [toasts, setToasts] = useState([])
  const [fatalErrorMessage, setFatalErrorMessage] = useState(null)
  const [dialogBox, setDialogBox] = useState({ show: false })
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    loggingIn: true,
  })
  const [timeoutModal, setTimeoutModal] = useState({ type: '', open: false })
  const [token, setToken] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState({ day: '', month: '', year: '' })

  const addToast = (statusId, intent, message) => {
    const toast = {
      id: statusId,
      intent,
      message,
    }

    setToasts([...toasts, toast])
  }

  return (
    <Context.Provider
      value={{
        loading,
        setLoading,
        demoMode,
        setDemoMode,
        dialogBox,
        setDialogBox,
        setAuthState,
        authState,
        getErrorMessage,
        fatalErrorMessage,
        addToast,
        timeoutModal,
        setTimeoutModal,
        setFatalErrorMessage,
        token,
        setToken,
        toasts,
        setToasts,
        dateOfBirth,
        setDateOfBirth,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </Context.Provider>
  )
}

export default App
