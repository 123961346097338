import { Container } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import Context from '../../utils/context/Context'
import { getLabel, renderContent, subQuestions } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import CancerHistory from '../../widgets/CancerHistory'
import CollapsibleQuestion from '../../widgets/CollapsibleQuestion'
import DateOfBirth from '../../widgets/DateOfBirth'
import FullName, { PreviousName } from '../../widgets/FullName'
import GenderIdentity from '../../widgets/GenderIdentity'
import LifeStatus from '../../widgets/LifeStatus'
import MeasurementsSection from '../../widgets/MeasurementsSection'
import SexAssignedAtBirth from '../../widgets/SexAssignedAtBirth'
import Adoption from './Adoption'
import Alcohol from './Alcohol'
import BreastBiopsy from './BreastBiopsy'
import BreastMammogram from './BreastMammogram'
import Cancer from './Cancer'
import Colonoscopy from './Colonoscopy'
import Conditions from './Conditions'
import ContraceptivePills from './ContraceptivePills'
import ElevatedPSA from './ElevatedPSA'
import Endometriosis from './Endometriosis'
import FIT from './FIT'
import GeneticTesting from './GeneticTesting'
import HRT from './HRT'
import MantleRadiation from './MantleRadiation'
import Medications from './Medications'
import Menopause from './Menopause'
import MenstrualPeriod from './MenstrualPeriod'
import ProstateBiopsy from './ProstateBiopsy'
import RecreationalDrugs from './RecreationalDrugs'
import Surgeries from './Surgeries'
import Tobacco from './Tobacco'
import Twins from './Twins'

function Person({ targetPerson = '', resolvePersonId = undefined }) {
  const { setLoading } = useContext(Context)
  const [personId, setPersonId] = useState('')

  const { removePersonPropertiesListFromAll, removePersonSexSpecificQuestions } = useActions()
  const localization = useLocalization()
  const probandId = useSelector((state) => state.questionnaire.probandId) || ''
  const sectionConfig = useSelector((state) => state.configuration.sectionConfig) || {}
  const isProband = targetPerson === 'proband'

  const configStep = () => {
    const enableFamilyCheck = (targetFamily, targetPerson) =>
      sectionConfig[targetFamily].enable
        ? sectionConfig[targetFamily].subSectionConfig[targetPerson]
        : {}

    switch (targetPerson) {
      case 'proband':
        return sectionConfig['your-information']
      case 'mother':
      case 'father':
        return enableFamilyCheck('your-family', targetPerson)
      case 'mat-gmother':
        return enableFamilyCheck('mothers-family', 'maternal-grandmother')
      case 'mat-gfather':
        return enableFamilyCheck('mothers-family', 'maternal-grandfather')
      case 'pat-gmother':
        return enableFamilyCheck('fathers-family', 'paternal-grandfather')
      case 'pat-gfather':
        return enableFamilyCheck('fathers-family', 'paternal-grandfather')
      default:
        return {}
    }
  }
  const config = configStep().questions

  const renderMap = {
    name: (
      <FullName
        key="full-name"
        id={personId}
        isProband={isProband}
        localization={localization}
        config={subQuestions({ config, key: 'name' })}
      />
    ),
    namePrevious: (
      <CollapsibleQuestion key="pre-name" label="Has your name changed?">
        <PreviousName id={personId} localization={localization} />
      </CollapsibleQuestion>
    ),
    dateOfBirth: <DateOfBirth key="personDOB" id={personId} />,
    sexAtBirth: isProband ? (
      <SexAssignedAtBirth
        key="SexAssignedAtBirth"
        label="Sex assigned at birth *"
        id={personId}
        clearSexSpecificQuestions={isProband && clearSexSpecificQuestions}
      />
    ) : null,
    notCisgender: <GenderIdentity key="GenderIdentity" id={personId} />,
    measurement: (
      <MeasurementsSection
        key="MeasurementsSection"
        id={probandId}
        config={subQuestions({ config, key: 'measurement' })}
      />
    ),
    twin: <Twins key="Twins" id={probandId} />,
    menstrualAge: <MenstrualPeriod key="MenstrualPeriod" id={probandId} />,
    oralContraceptive: (
      <ContraceptivePills
        key="ContraceptivePills"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'oral-contraceptive' })}
      />
    ),
    menopause: (
      <Menopause
        key="Menopause"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'menopause' })}
      />
    ),
    hrtUse: (
      <HRT
        key="hrt"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'hrt-use' })}
      />
    ),
    tobacco: (
      <Tobacco
        key="tob"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'tobacco' })}
      />
    ),
    alcohol: (
      <Alcohol
        key="alc"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'alcohol' })}
      />
    ),
    cancerHx: (
      <Cancer key="can" id={probandId} patient={'patient'}>
        <CancerHistory
          label={getLabel(localization, paths.TYPES_OF_CANCER, 'person')}
          personId={probandId}
          config={subQuestions({ config, key: 'cancer-hx' })}
        />
      </Cancer>
    ),
    mantleRadiation: <MantleRadiation key="mra" id={probandId} />,
    endometriosisStatus: <Endometriosis key="end" id={probandId} patient="patient" />,
    fitHx: (
      <FIT
        key="fit"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'fit-hx' })}
      />
    ),
    colonoscopyHx: (
      <Colonoscopy
        key="col"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'colonoscopy-hx' })}
      />
    ),
    breastMammogram: (
      <BreastMammogram
        key="bm"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'breast-mammogram' })}
      />
    ),
    breastBiopsy: (
      <BreastBiopsy
        key="bb"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'breast-biopsy' })}
      />
    ),
    prostateBiopsy: (
      <ProstateBiopsy
        key="pb"
        id={probandId}
        config={subQuestions({ config, key: 'prostate-biopsy' })}
      />
    ),
    psaResult: <ElevatedPSA key="ElevatedPSA" id={probandId} />,
    surgeries: <Surgeries key="Surgeries" id={probandId} />,
    conditions: (
      <Conditions
        key="Conditions"
        id={probandId}
        config={subQuestions({ config, key: 'conditions' })}
      />
    ),
    geneticTestHistory: (
      <GeneticTesting
        key="gt"
        id={probandId}
        patient="patient"
        config={subQuestions({ config, key: 'genetic-test-history' })}
      />
    ),
    medications: <Medications key="med" id={probandId} config={config} />,
    recreationalDrugs: <RecreationalDrugs key="rec-drugs" id={probandId} />,
    adopted: renderAdoptionInfo(),
    fatherAdopted: renderAdoptionInfo(),
    motherAdopted: renderAdoptionInfo(),
    lifeStatus: (
      <LifeStatus
        key="LifeStatus"
        personId={personId}
        isProband={isProband}
        config={subQuestions({ config, key: 'lifeStatus' })}
      />
    ),
  }

  const content = []
  renderContent({ renderMap, config, content })

  useEffect(() => {
    setLoading(() => {})
  }, [])

  useEffect(() => {
    setPersonId(resolvePersonId ? resolvePersonId(true) : probandId)
  }, [resolvePersonId])

  return <Container sx={{ gap: 4, pb: 8 }}>{content}</Container>

  function renderAdoptionInfo() {
    return <Adoption key="Adoption" id={personId} targetPerson={targetPerson} />
  }

  /**
   * Reset all properties that are sex specific
   */
  function clearSexSpecificQuestions() {
    removePersonPropertiesListFromAll({ type: 'twin' })
    removePersonSexSpecificQuestions({ id: probandId })
  }
}

export default Person
