import { FormGroup } from '@mui/material'
import React from 'react'

const CheckBoxList = ({ children, sx, ...props }) => (
  <FormGroup sx={{ pl: 2, ...sx }} {...props}>
    {children}
  </FormGroup>
)

export default CheckBoxList
