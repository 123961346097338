import { Box, Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material'
import { get, includes, find, findIndex, map } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import CheckBoxList from '../../widgets/CheckBoxList'
import Chooser from '../../widgets/Chooser'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'

const Surgeries = ({ id }) => {
  const { removePersonSurgeries, setPersonSurgeries } = useActions()
  const localization = useLocalization()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex']))
  const surgeries = useSelector((state) => get(state, [...pathToPerson(id), 'surgeries'])) || []

  const surgeriesList = [
    {
      id: 'lumpectomy',
      name: getLabel(localization, paths.LUMPECTOMY_LABEL, 'patient'),
      tissue: 'breasts',
    },
    {
      id: 'mastectomy',
      name: getLabel(localization, paths.MASTECTOMY_LABEL, 'patient'),
      tissue: 'breasts',
    },
    {
      id: 'hysterectomy',
      name: getLabel(localization, paths.HYSTERECTOMY_LABEL, 'patient'),
      tissue: 'uterus',
    },
    {
      id: 'tubalLigation',
      name: getLabel(localization, paths.TUBAL_LIGATION_LABEL, 'patient'),
      tissue: 'tubes',
    },
    {
      id: 'oophorectomy',
      name: getLabel(localization, paths.OOPHORECTOMY_LABEL, 'patient'),
      tissue: 'ovaries',
    },
    {
      id: 'salpingectomy',
      name: getLabel(localization, paths.SALPINGECTOMY_LABEL, 'patient'),
      tissue: 'fallopian tubes',
    },
  ]
  const handleSurgeryChange = (surgery) => () => {
    const surgeryIndex = findIndex(surgeries, { type: surgery.id })

    if (surgeryIndex >= 0) {
      removePersonSurgeries({ id, type: surgery.id })
    } else {
      setPersonSurgeries({
        id,
        surgeryType: surgery.id,
        type: 'type',
        value: surgery.id,
      })
    }
  }

  const surgeriesWithSide = ['lumpectomy', 'mastectomy', 'salpingectomy', 'oophorectomy']

  return sex === 'F' ? (
    <FormControl>
      <FormLabel>{getLabel(localization, paths.SURGERY_LABEL, 'patient')}</FormLabel>
      <CheckBoxList>
        {surgeriesList.map((surgery) => {
          const isChecked = includes(map(surgeries, 'type'), surgery.id)
          const selectedValue = find(surgeries, { type: surgery.id })

          return (
            <Box key={surgery.id}>
              <FormControlLabel
                label={surgery.name}
                control={<Checkbox checked={isChecked} onChange={handleSurgeryChange(surgery)} />}
              />
              {isChecked && includes(surgeriesWithSide, surgery.id) && (
                <FollowUpQuestions>
                  <Chooser
                    label={`One or both ${surgery.tissue}?`}
                    onChange={(value) => {
                      if (value !== selectedValue.lateralityType) {
                        setPersonSurgeries({
                          id,
                          surgeryType: surgery.id,
                          type: 'lateralityType',
                          value,
                        })
                      }
                    }}
                    selectedValue={selectedValue.lateralityType}
                    orderSchema={['left', 'right', 'bothSides']}
                    patient={null}
                    path="laterality"
                    subQuestion
                  />
                </FollowUpQuestions>
              )}
            </Box>
          )
        })}
      </CheckBoxList>
    </FormControl>
  ) : null
}

export default Surgeries
