import React, { useContext } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import Auth from './pages/Auth'
import Loading from './pages/Loading'
import Message from './pages/Message'
import PageNotFound from './pages/PageNotFound'
import Questionnaire from './pages/Questionnaire'
import ThankYou from './pages/ThankYou.jsx'
import UserLogout from './pages/UserLogout'
import Context from './utils/context/Context'
import history from './utils/history'
import AlertBar from './widgets/AlertBar'
import DialogWindow from './widgets/DialogWindow'

function Routes() {
  const { setToasts, toasts, timeoutModal, setTimeoutModal, setLoading } = useContext(Context)

  return (
    <>
      <AlertBar
        toasts={toasts}
        removeToast={(i) => {
          const newToasts = [...toasts]
          newToasts.splice(i, 1)
          setToasts(newToasts)
        }}
      />
      <Router history={history}>
        {/* We can set header over here */}
        <Switch>
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/questionnaire" component={Questionnaire} />
          <Route exact path="/thankyou" component={ThankYou} />
          <Route exact path="/message" component={Message} />
          <Route exact path="/user-logout" component={UserLogout} />
          <Route exact path="/" component={Loading} />
          <Route path="*" component={PageNotFound} />
        </Switch>
        {/* we can set footer over here */}
      </Router>
      {timeoutModal.open && timeoutModal.type === 'timedOut' && (
        <DialogWindow
          open={timeoutModal.open && timeoutModal.type === 'timedOut'}
          title="Session expired"
          text={`This session has timed out due to inactivity. \
              To resume, please click or copy-paste the link you received \
              from your health care provider again.`}
          color="error"
          noSecondary
          handleConfirm={() => {
            setTimeoutModal({ open: false, type: '' })
            setLoading((prevState) => ({
              ...prevState,
              dialog: false,
            }))
          }}
        />
      )}
    </>
  )
}

export default Routes
