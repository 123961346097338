import { FormControl, FormLabel, TextField } from '@mui/material'
import { debounce } from 'lodash'
import React, { useMemo, useState } from 'react'
import { SubQuestionLabel } from './FormLabel'

const TextFieldQuestion = ({
  label,
  value,
  handleChange,
  fullWidth = false,
  multiline = true,
  subQuestion = false,
}) => {
  const [text, setText] = useState(value)
  const debounceAction = useMemo(() => debounce(handleChange, 200), [handleChange])
  const LabelComponent = subQuestion ? SubQuestionLabel : FormLabel

  return (
    <FormControl label={label} sx={{ width: fullWidth ? '100%' : 350 }}>
      <LabelComponent>{label}</LabelComponent>
      <TextField
        fullWidth={fullWidth}
        multiline={multiline}
        inputProps={{ maxLength: 65000, 'aria-label': label }}
        onChange={({ target: { value } }) => {
          setText(value)
          debounceAction(value)
        }}
        value={text}
      />
    </FormControl>
  )
}

export default TextFieldQuestion
