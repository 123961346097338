import { Box } from '@mui/material'
import React from 'react'
import { theme } from '../theme'

const FollowUpQuestions = ({ children, ...props }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      p={3}
      backgroundColor={theme.palette.primary.bg}
      borderRadius={4}
      {...props}
    >
      {children}
    </Box>
  )
}

export default FollowUpQuestions
