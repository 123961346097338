import { Autocomplete, Checkbox, TextField, FormLabel, FormControl } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import React from 'react'
import { theme } from '../theme'
import { charRestriction } from '../utils/helpers'

const filter = createFilterOptions()

/**
 * Dropdown selector with search and autocomplete for options
 * @param value single object if multiple is false
 * @param options format of { value: '...', label: '...' } or the equivalency check will fail
 */
const SearchSelect = ({
  label,
  value,
  options,
  onChange,
  inputLabel,
  filterOptions,
  multiple = false,
}) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Autocomplete
        sx={{ pt: 1 }}
        disableCloseOnSelect
        clearOnBlur
        multiple={multiple}
        blurOnSelect={!multiple}
        freeSolo
        value={value}
        isOptionEqualToValue={({ value }, { value: _value }) => value === _value}
        options={options}
        getOptionLabel={(option) => option.label || ''}
        renderOption={(props, { value, label }, { selected }) => (
          <li key={value} {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {label}
          </li>
        )}
        renderInput={({ inputProps, ...params }) => (
          <TextField
            {...params}
            sx={{ color: theme.palette.primary.main }}
            label={inputLabel || 'Search...'}
            onChangeCapture={(e) => {
              e.target.value = charRestriction(e.target.value)
            }}
            inputProps={{
              ...inputProps,
              'aria-label': `Autocomplete search ${
                multiple ? 'multiple select, backspace to remove' : ''
              }`,
            }}
          />
        )}
        onChange={(e, newValue) => {
          // Filter out null values in multiple select arrays
          if (Array.isArray(newValue)) {
            const filteredValue = newValue.filter((v) => v !== null && typeof v === 'object')

            return onChange(e, filteredValue)
          }

          onChange(e, newValue)
        }}
        filterOptions={filterOptions || defaultFilteredOptions}
      />
    </FormControl>
  )

  function defaultFilteredOptions(options, params) {
    const filtered = filter(options, params)

    const { inputValue } = params
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.value)
    if (inputValue !== '' && !isExisting) {
      filtered.push({ value: inputValue, label: `Add "${inputValue}"` })
    }

    return filtered
  }
}

export default SearchSelect
