import { Container } from '@mui/material'
import React from 'react'

const CenteredContainer = ({ children, ...props }) => (
  <Container maxWidth="sm" sx={{ alignItems: 'center', pt: '20vh' }} {...props}>
    {children}
  </Container>
)

export default CenteredContainer
