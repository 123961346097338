import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

export const AdditionalNotes = ({ label }) => {
  const notes = useSelector((state) => state.questionnaire.notes)
  const { setAdditionalInformation } = useActions()

  return (
    <TextFieldQuestion
      fullWidth
      label={label}
      value={notes}
      handleChange={(value) => setAdditionalInformation({ value })}
    />
  )
}

export const AdditionalHealthProblems = ({ label }) => {
  const additionalHealthProblems = useSelector(
    (state) => state.questionnaire.additionalHealthProblems,
  )
  const { setAdditionalHealthProblems } = useActions()

  return (
    <TextFieldQuestion
      fullWidth
      label={label}
      value={additionalHealthProblems}
      handleChange={(value) => setAdditionalHealthProblems({ value })}
    />
  )
}

export const RelevantInfo = ({ label }) => {
  const relevantInfo = useSelector((state) => state.questionnaire.relevantInfo)
  const { setAdditionalRelevantInfo } = useActions()

  return (
    <TextFieldQuestion
      fullWidth
      label={label}
      value={relevantInfo}
      handleChange={(value) => setAdditionalRelevantInfo({ value })}
    />
  )
}
