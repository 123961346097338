import {
  getPerson,
  removePersonObjectProperty,
  setPersonObjectProperty,
} from '../../../slices/questionnaireSlice/utils/helpers'

export default {
  /**
   * Set a property in person.geneticTesting: { performed: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: string }}
   * )}
   */
  setPersonGeneticTesting(state, action) {
    setPersonObjectProperty(state, action, 'geneticTesting')
  },
  /**
   * Remove property in person.geneticTesting: { performed: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string }}
   * )}
   */
  removePersonGeneticTesting(state, action) {
    removePersonObjectProperty(state, action, 'geneticTesting')
  },
  /**
   * Remove property in relative.geneticTesting: { performed: 'Y', description: 'description' }
   * @type {(state: WritableDraft<{}>,
   */
  removeRelativeGeneticTesting(state, action) {
    const { type } = action.payload
    const person = getPerson(state, action)

    person[type] = {}
  },
  /**
   * Remove the entire list person.cancerRiskData.geneTests
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string }})}
   */
  removeAllPersonGeneTests: (state, action) => {
    const person = getPerson(state, action)
    if (!person.cancerRiskData.geneTests) {
      throw new Error('Unable to find geneTests.')
    }

    delete person.cancerRiskData.geneTests
  },
}
