import { Box, Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization, useShallowSelector } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent, subQuestions } from '../../utils/helpers'
import { PersonConditions, PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import CheckBoxList from '../../widgets/CheckBoxList'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const conditionsMap = {
  pancreatitis: { conditionId: PersonConditions.PANCREATITIS, labelPath: paths.PANCREATITIS },
  tumour: { conditionId: PersonConditions.TUMOUR, labelPath: paths.TUMOUR },
  pituitary: { conditionId: PersonConditions.PITUITARY, labelPath: paths.PITUITARY },
  moles: { conditionId: PersonConditions.MOLE_NEVI, labelPath: paths.MOLE_NEVI },
  polyps: { conditionId: PersonConditions.POLYPS_REMOVED, labelPath: paths.POLYPS_REMOVED },
  endometriosis: { conditionId: PersonConditions.ENDOMETRIOSIS, labelPath: paths.ENDOMETRIOSIS },
  bloodClot: { conditionId: PersonConditions.BLOOD_CLOT, labelPath: paths.BLOOD_CLOT },
}

const ConditionSwitch = ({ conditionId, id, label, isChecked }) => {
  const { setPersonConditions, removePersonConditions } = useActions()

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={(e) => {
            e.currentTarget.checked
              ? setPersonConditions({ id, conditionId })
              : removePersonConditions({ id, conditionId })
          }}
        />
      }
      label={label}
    />
  )
}

const ConditionDescription = ({ conditionId, id }) => {
  const { setPersonConditionNotes } = useActions()
  const condition = useSelector((state) => {
    const conditions = get(state, [...pathToPerson(id), 'conditions']) || []

    return conditions.find(({ id }) => id === conditionId)
  })

  return (
    <FollowUpQuestions>
      <TextFieldQuestion
        label="Please describe"
        value={condition?.notes || ''}
        handleChange={(value) => setPersonConditionNotes({ id, conditionId, value })}
        subQuestion
      />
    </FollowUpQuestions>
  )
}

const Conditions = ({ config, id }) => {
  const localization = useLocalization()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const conditions =
    useShallowSelector((state) => get(state, [...pathToPerson(id), 'conditions'])) || []

  const content = []
  renderContent({ renderMap: conditionsMap, config, content })

  return (
    <FormControl>
      <FormLabel>Have you had any of the following conditions?</FormLabel>
      <CheckBoxList>
        {content.map(({ conditionId, labelPath }) => {
          const condition = conditions.find(({ id }) => id === conditionId)
          const isChecked = condition?.isPresent === 'Y'
          const subConfig = subQuestions({ config, key: conditionId })

          // endometriosis only for female
          return conditionId === PersonConditions.ENDOMETRIOSIS && sex !== 'F' ? null : (
            <Box key={'condition-' + conditionId}>
              <ConditionSwitch
                conditionId={conditionId}
                labelPath={labelPath}
                label={getLabel(localization, labelPath, paths.CONDITIONS)}
                isChecked={isChecked}
                sex={sex}
                id={id}
              />
              {isChecked &&
                renderContent({
                  renderMap: {
                    conditionsDescribe: (
                      <ConditionDescription
                        key={'description-' + conditionId}
                        conditionId={conditionId}
                        id={id}
                      />
                    ),
                  },
                  config: subConfig,
                })}
            </Box>
          )
        })}
      </CheckBoxList>
    </FormControl>
  )
}

export default Conditions
