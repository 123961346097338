import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Auth as AuthJS } from '../api/Auth'
import Context from '../utils/context/Context'
import CenteredContainer from '../widgets/CenteredContainer'
import { DOBInputs } from '../widgets/DateOfBirth'
import Spinner from '../widgets/Spinner'

const Auth = () => {
  const { token, setToken, setDateOfBirth } = useContext(Context)
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [invalidDate, setInvalidDate] = useState(false)
  const [dates, setDates] = useState({
    day: '',
    month: '',
    year: '',
  })
  const inputProps = [
    {
      value: dates.year,
      label: 'Year',
      dateType: 'year',
      valueRange: [1850, new Date().getFullYear()],
      disabled: false,
    },
    {
      value: dates.month,
      label: 'Month',
      dateType: 'month',
      valueRange: [1, 12],
      disabled: !dates.year,
    },
    {
      value: dates.day,
      label: 'Day',
      dateType: 'day',
      valueRange: [1, 31],
      disabled: !dates.month,
    },
  ]

  // state cleanup on unmount to prevent react warning message
  useEffect(() => {
    return () => {
      setLoading(null)
    }
  }, [])

  if (!token) {
    return <Redirect to="/questionnaire" />
  }

  if (loading) {
    return <Spinner message="Loading..." />
  }

  return (
    <CenteredContainer>
      <VerifiedUserIcon color="primary" sx={{ fontSize: 50 }} />
      <Typography variant="h2" color="primary">
        User Verification
      </Typography>
      <Typography color="primary">
        Please enter the patient&lsquo;s date of birth to continue
      </Typography>
      <Box display="flex" gap={1} maxWidth={350} width="100%">
        {inputProps.map(({ value, label, dateType, valueRange, disabled }) => (
          <DOBInputs
            key={'dob-' + dateType}
            value={value}
            label={label}
            dateType={dateType}
            valueRange={valueRange}
            disabled={disabled}
            onChange={({ target: { value } }) => {
              setDates((prevState) => ({
                ...prevState,
                [dateType]: value,
              }))
            }}
          />
        ))}
      </Box>
      {invalidDate ? (
        <Typography variant="body2" color="error">
          Invalid date please try again
        </Typography>
      ) : null}
      <LoadingButton
        onClick={async () => {
          setLoading(true)
          await handleConfirm()
        }}
        variant="contained"
        color="primary"
        loading={loading}
      >
        <span>Submit</span>
      </LoadingButton>
    </CenteredContainer>
  )

  async function handleConfirm() {
    if (dates.day && dates.month && dates.year) {
      await AuthJS.login({
        accessToken: token,
        success() {
          return new Promise((resolve) => {
            setDateOfBirth(dates)
            setToken(() => {
              resolve(true)

              return ''
            })
          })
        },
        failure(status) {
          // 400 = birth date validation failed
          if (status === '400') {
            setInvalidDate(true)

            return false
          }

          return true
        },
        redirect() {
          history.push('/questionnaire')
        },
        dateOfBirth: dates,
      })
    } else {
      setInvalidDate(true)
    }
    setLoading(false)
  }
}

export default Auth
