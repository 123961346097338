/**
 * TODO: The comments below need to leave it can be implemented
 * after the current-session is resolved.
 */
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { Alert, Button, Typography } from '@mui/material'
import React from 'react'
import RemoveLoadingHook from '../hooks/RemoveLoadingHook'
// import { useActions } from '../hooks/useActions'
import CenteredContainer from '../widgets/CenteredContainer'

export default function ThankYou({ history }) {
  // const { sendMetricsData } = useActions()
  const { externalUrl: { instructions = '', url = '', urlLabel = '' } = {} } =
    history.location.state || {}

  RemoveLoadingHook()

  /**
   * Invoke `sendMetricsData` to send the metric data.
   * @returns {Promise<undefined>}
   *
   * Todo: Still need to wait until
   * the backend resolves `Unauthorized` and `Session` issues.
   */
  // const handleMetrics = async (event) => {
  //   event.preventDefault()

  //   return sendMetricsData({
  //     newDestinationValue: 'Feedback',
  //     eventType: EventType.FEEDBACK,
  //     shouldNotRunDispatch: true,
  //     redirect() {
  //       window.location.ref = url
  //     },
  //   })
  // }

  const hasURL = !!urlLabel && !!url

  return (
    <CenteredContainer>
      <TaskAltIcon color="primary" sx={{ fontSize: 50 }} />
      <Typography variant="h2" color="primary" textAlign="center">
        Thank you for submitting!
      </Typography>
      <>
        <Typography variant="body2" align="center">
          Thank you for taking the time to fill out this questionnaire. Your Pre-visit questionnaire
          has been submitted.
        </Typography>
        {instructions && (
          <Alert severity="info">
            {instructions} <br />
            {/* <Link onClick={handleMetrics}>{urlLabel}</Link> */}
          </Alert>
        )}
        {hasURL && (
          <Button variant="contained" href={url}>
            {urlLabel}
          </Button>
        )}
      </>
    </CenteredContainer>
  )
}
