import {
  getPerson,
  removePersonListObjectProperty,
  setPersonListObjectProperty,
} from '../../../slices/questionnaireSlice/utils/helpers'

export default {
  /**
   * Set property to list item type: 'fit' in person.diagnosticTests[]: { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: string }}
   * )}
   */
  setPersonFITStatus(state, action) {
    setPersonListObjectProperty(state, action, 'fit', 'diagnosticTests')
  },
  /**
   * Remove property from list item type: 'fit' in person.diagnosticTests[]: { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  removePersonFITStatus(state, action) {
    removePersonListObjectProperty(state, action, 'fit', 'diagnosticTests')
  },
  /**
   * Set property to list item type: 'colonoscopy' in person.diagnosticTests[]:
   * { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: string }}
   * )}
   */
  setPersonColonoscopyStatus(state, action) {
    setPersonListObjectProperty(state, action, 'colonoscopy', 'diagnosticTests')
  },
  /**
   * Set the bowelPolyps on the diagnosticTestQualifiers list specific to the colonoscopy test
   * ie. person.diagnosticTests[{type: 'colonoscopy', diagnosticTestQualifiers: [{
   *  diagnosticTestQualifierType: 'bowelPolyps'
   * }]}]
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, value: string }})}
   */
  setPersonBowelPolyps: (state, action) => {
    const person = getPerson(state, action)
    const { value } = action.payload
    if (!person.diagnosticTests) throw new Error('Person does not have any diagnostic tests')

    const colonoscopy = person.diagnosticTests.find(({ type }) => type === 'colonoscopy')

    if (!colonoscopy) throw new Error('Person does not have colonoscopy')

    colonoscopy.diagnosticTestQualifiers = [
      {
        diagnosticTestQualifierType: 'bowelPolyps',
        isPresent: value,
      },
    ]
  },
  /**
   * Set the value for bowelPolyps for colonoscopy qualifiers
   * ie. person.diagnosticTests[{type: 'colonoscopy', diagnosticTestQualifiers: [{
   *  value: '7'
   * }]}]
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, value: string }})}
   */
  setPersonBowelPolypsCount: (state, action) => {
    const person = getPerson(state, action)
    const { value } = action.payload
    const colonoscopy = person.diagnosticTests?.find(({ type }) => type === 'colonoscopy')

    if (!colonoscopy) throw new Error('Person does not have colonoscopy')

    const bowelPolyps = colonoscopy.diagnosticTestQualifiers?.find(
      ({ diagnosticTestQualifierType }) => diagnosticTestQualifierType === 'bowelPolyps',
    )

    if (!bowelPolyps) throw new Error('Person does not have bowelPolyps')

    bowelPolyps.value = value
  },
  /**
   * Remove property from list item type: 'colonoscopy'
   * ie. person.diagnosticTests[]: { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  removePersonColonoscopyStatus(state, action) {
    removePersonListObjectProperty(state, action, 'colonoscopy', 'diagnosticTests')
  },
  /**
   * Set property to list item type: 'breastMammogram'
   * ie. person.diagnosticTests[]: { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: string }}
   * )}
   */
  setPersonBreastMammogramStatus(state, action) {
    setPersonListObjectProperty(state, action, 'breastMammogram', 'diagnosticTests')
  },
  /**
   * Remove property from list item type: 'breastMammogram'
   * ie. person.diagnosticTests[]: { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  removePersonBreastMammogramStatus(state, action) {
    removePersonListObjectProperty(state, action, 'breastMammogram', 'diagnosticTests')
  },
  /**
   * Set property to list item type: 'prostateBiopsy'
   * ie. person.diagnosticTests[]: { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: string }}
   * )}
   */
  setPersonElevatedPSA(state, action) {
    setPersonListObjectProperty(state, action, 'elevatedPSA', 'diagnosticTests')
  },
  /**
   * Remove property from list item type: 'elevatedPSA'
   * ie. person.diagnosticTests[]: { isPerformed: 'Y' }
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  removePersonElevatedPSA: (state, action) => {
    removePersonListObjectProperty(state, action, 'elevatedPSA', 'diagnosticTests')
  },
}
