import { Box, Checkbox, FormControl, FormControlLabel } from '@mui/material'
import { get, isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useProband } from '../../redux/reduxHooks'
import { pathToPerson } from '../../utils/helpers'
import { PersonProperties, PersonSurgeries } from '../../utils/QuestionnaireStateManager'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import { SubQuestionLabel } from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

let time = null

function RelativeSurgery({
  personId,
  personLabel,
  lastItemUnchecked,
  surgeryType,
  probandProperty,
}) {
  const { setPersonSurgeries, removePersonSurgeries, setPersonPropertiesIsPresent } = useActions()
  const { id: probandId = '', properties: probandProperties = [] } = useProband() || {}
  const surgery =
    useSelector((state) => {
      const surgeries = get(state, [...pathToPerson(personId), 'surgeries']) || []

      return surgeries.find(({ type }) => type === surgeryType)
    }) || {}

  const { age = '', reason = '' } = surgery

  const isChecked = !isEmpty(surgery)

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <FormControl>
            <SubQuestionLabel>Age removed?</SubQuestionLabel>
            <IntegerDetails onChange={handleAge} value={age} />
          </FormControl>
          <TextFieldQuestion
            fullWidth
            inputProps={{ maxLength: 255 }}
            handleChange={handleTextArea}
            value={reason}
            label={'What was the reason?'}
            subQuestion
          />
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    const hasProbandProperty =
      probandProperties.find(({ type }) => type === probandProperty)?.isPresent !== 'Y'

    // on checkbox selected
    if (e.target.checked) {
      if (hasProbandProperty) {
        setPersonPropertiesIsPresent({
          id: probandId,
          type: probandProperty,
          value: 'Y',
        })
      }
      setPersonSurgeries({
        id: personId,
        surgeryType,
        type: 'type',
        value: surgeryType,
      })

      return
    }
    // on checkbox deselected
    if (lastItemUnchecked()) {
      setPersonPropertiesIsPresent({
        id: probandId,
        type: probandProperty,
        value: 'N',
      })
    }
    removePersonSurgeries({
      id: personId,
      type: surgeryType,
    })
  }

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {number} value IntegerDetails' number value
   **/
  function handleAge(value) {
    setPersonSurgeries({
      id: personId,
      surgeryType,
      type: 'age',
      value,
    })
  }
  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleTextArea(value) {
    if (time) {
      clearTimeout(time)
    }

    time = setTimeout(() => {
      setPersonSurgeries({
        id: personId,
        surgeryType,
        type: 'reason',
        value,
      })
    }, 5)
  }
}

function Hysterectomy({ ...props }) {
  return (
    <RelativeSurgery
      surgeryType={PersonSurgeries.HYSTERECTOMY}
      probandProperty={PersonProperties.HAS_RELATIVE_HAVE_HYSTERECTOMY}
      {...props}
    />
  )
}

function Oophorectomy({ ...props }) {
  return (
    <RelativeSurgery
      surgeryType={PersonSurgeries.OOPHORECTOMY}
      probandProperty={PersonProperties.HAS_RELATIVE_REMOVED_OVARIES}
      {...props}
    />
  )
}

export { Hysterectomy, Oophorectomy }
