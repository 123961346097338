import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson } from '../../utils/helpers'
import Chooser from '../../widgets/Chooser'

const RecreationalDrugs = ({ id }) => {
  const { setPersonPropertiesIsPresent, setPersonPropertiesValue } = useActions()
  const { value = '', isPresent = '' } =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(id), 'properties']) || []

      return properties.find((property) => property.type === 'takesRecreationalDrug')
    }) || {}
  const drugHistory = () => {
    if (isPresent === 'Y') {
      return value
    } else if (isPresent === 'N') {
      return 'no'
    } else {
      return ''
    }
  }
  const actionProps = { id, type: 'takesRecreationalDrug' }

  return (
    <Chooser
      label="Have you ever used recreational drugs?"
      orderSchema={['currently', 'previously', 'no']}
      options={[
        ['currently', 'Yes, currently'],
        ['previously', 'Yes, previously'],
        ['no', 'No'],
      ]}
      patient={'patient'}
      selectedValue={drugHistory()}
      onChange={(value) => {
        if (value !== 'no') {
          setPersonPropertiesIsPresent({ ...actionProps, value: 'Y' })
          setPersonPropertiesValue({ ...actionProps, value })
        } else {
          setPersonPropertiesIsPresent({ ...actionProps, value: 'N' })
          setPersonPropertiesValue({ ...actionProps, value: '' })
        }
      }}
    />
  )
}

export default RecreationalDrugs
