export const CANCER_CATEGORIES = [
  {
    label: 'Breast',
    id: 'HP:0100013',
  },
  {
    label: 'Ovarian',
    id: 'HP:0100615',
    genderRestriction: 'F',
  },
  {
    label: 'Colon (Bowel)',
    id: 'HP:0003003',
  },
  {
    label: 'Endometrial (uterus)',
    id: 'HP:0010784',
    genderRestriction: 'F',
  },
  {
    label: 'Prostate',
    id: 'HP:0012125',
    genderRestriction: 'M',
  },
  {
    label: 'Pancreatic',
    id: 'HP:0002894',
  },
  {
    label: 'Skin',
    id: 'HP:0008069',
  },
  {
    label: 'Kidney and urinary tract',
    id: 'HP:0010786',
  },
  {
    label: 'Gastric (stomach)',
    id: 'HP:0006753',
  },
  {
    label: 'Brain',
    id: 'HP:0030692',
  },
  {
    label: 'Liver',
    id: 'HP:0002896',
  },
  {
    label: 'Small intestine',
    id: 'HP:0100833',
  },
  {
    label: 'Adrenocortical tumour',
    id: 'HP:0006744',
  },
  {
    label: 'Cervix',
    id: 'HP:0030079',
  },
  {
    label: 'Hodgkin lymphoma',
    id: 'HP:0012189',
  },
  {
    label: 'Leukemia',
    id: 'HP:0001909',
  },
  {
    label: 'Lung',
    id: 'HP:0100526',
  },
  {
    label: 'Osteosarcoma',
    id: 'HP:0002669',
  },
  {
    label: 'Retinoblastoma',
    id: 'HP:0009919',
  },
  {
    label: 'Sarcoma',
    id: 'HP:0100242',
  },
  {
    label: 'Thyroid',
    id: 'HP:0100031',
  },
  {
    label: 'Other',
    id: 'Other',
  },
]

export const BREAST_TYPES = [
  {
    label: 'Breast cancer',
    id: 'HP:0003002',
  },
  {
    label: 'DCIS (ductal carcinoma in situ)',
    id: 'HP:0030075',
  },
]

export const OVARIAN_TYPES = [
  {
    label: 'Epithelial ovarian cancer',
    id: 'HP:0025318',
  },
  {
    label: 'Fallopian tube cancer',
    id: 'HP:0030394',
  },
  {
    label: 'Cancer that started in the peritoneum',
    id: 'HP:0030406',
  },
  {
    label: 'Other type of ovarian cancer',
    id: 'Other ovarian cancer (non-epithelial)',
  },
]

export const SKIN_TYPES = [
  {
    label: 'Basal Cell Carcinoma',
    id: 'HP:0002671',
  },
  {
    label: 'Squamous Cell Carcinoma',
    id: 'HP:0002860',
  },
  {
    label: 'Sebaceous Gland Carcinoma',
    id: 'HP:0030410',
  },
  {
    label: 'Leiomyoma',
    id: 'HP:0007620',
  },
  {
    label: 'Melanoma',
    id: 'HP:0012056',
  },
]

export const KIDNEY_AND_URINARY_TYPES = [
  {
    label: 'Kidney cancer',
    id: 'HP:0009726',
  },
  {
    label: 'Ureter cancer',
    id: 'HP:0100516',
  },
  {
    label: 'Bladder cancer',
    id: 'HP:0009725',
  },
  {
    label: 'Bile duct cancer',
    id: 'HP:0030153',
  },
]

export const BRAIN_TYPES = [
  {
    label: 'Astrocytoma',
    id: 'HP:0009592',
  },
  {
    label: 'Glioblastoma',
    id: 'HP:0012174',
  },
  {
    label: 'Medulloblastoma',
    id: 'HP:0002885',
  },
]

export const COLON_TYPES = [
  {
    label: 'Colon (Bowel)',
    id: 'HP:0003003',
  },
  {
    label: 'Rectum',
    id: 'HP:0100743',
  },
]

export const BREAST_LATERALITY = [
  {
    label: 'Left only',
    id: 'left',
  },
  {
    label: 'Right only',
    id: 'right',
  },
  {
    label: 'Both sides',
    id: 'bothSides',
  },
]

const ALL_CANCERS = [
  ...CANCER_CATEGORIES,
  ...BREAST_TYPES,
  ...OVARIAN_TYPES,
  ...SKIN_TYPES,
  ...KIDNEY_AND_URINARY_TYPES,
  ...BRAIN_TYPES,
  ...COLON_TYPES,
]

export const findCancerLabel = (id) => {
  const cancerFound = ALL_CANCERS.filter((cancer) => cancer.id === id)

  if (cancerFound.length === 0) return

  return cancerFound[0].label
}
