import { Container, Typography, FormLabel as MuiFormLabel } from '@mui/material'
import React from 'react'

const FormLabel = ({ label, fullWidth = false, children, id, ...props }) => {
  return (
    <Container {...props} sx={{ gap: 1, p: 0 }}>
      <Typography variant="body2" maxWidth={fullWidth ? '100%' : 600} id={id} aria-hidden={true}>
        {label}
      </Typography>
      {children}
    </Container>
  )
}

export const SubQuestionLabel = ({ children, sx, ...props }) => {
  return (
    <MuiFormLabel sx={{ fontSize: '1rem', ...sx }} {...props}>
      {children}
    </MuiFormLabel>
  )
}

export default FormLabel
