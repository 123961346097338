import { Alert, Snackbar } from '@mui/material'
import React from 'react'

function AlertBar({ toasts, removeToast }) {
  return toasts.map((toast, i) => (
    <Snackbar
      open
      key={'alert' + i}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={() => removeToast(i)}
    >
      <Alert severity={toast.intent} onClose={() => removeToast(i)}>
        {toast.message}
      </Alert>
    </Snackbar>
  ))
}
export default AlertBar
