import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import { renderContent } from '../../utils/helpers'
import Chooser from '../../widgets/Chooser'
import ConditionalSection from '../../widgets/ConditionalSection'
import RelativeSelect from './RelativeSelect'

const Consent = () => {
  const { consents = [], configuration } = useSelector(({ questionnaire, configuration }) => ({
    consents: questionnaire.consents,
    configuration,
  }))
  const { setConsentsGranted, setConsentsExcludedPersons } = useActions()
  const share = consents.find((consent) => consent.type === 'share') || {}
  const anonymizedMedicalResearch =
    consents.find((consent) => consent.type === 'anonymizedMedicalResearch') || {}
  const config = configuration.sectionConfig.consent?.questions || []

  RemoveLoadingHook()

  return (
    <Container>
      {renderContent({
        renderMap: {
          cardiacConsentRelatives: (
            <ConditionalSection
              key="cardiacConsentRelatives"
              label="Can we share the information you provided in this questionnaire
        with relatives for the purpose of their medical care?"
              conditionState={share.granted || ''}
              onConditionStateChange={(value) => setConsentsGranted({ value, type: 'share' })}
              path="yesNoUnknown"
              orderSchema={['Y', 'N']}
              showWhenCondition={'Y'}
              cleanUpChildQuestions={() => setConsentsExcludedPersons({ value: [], type: 'share' })}
              fullWidth
            >
              <RelativeSelect />
            </ConditionalSection>
          ),
          cardiacConsentResearch: (
            <Chooser
              key="cardiacConsentResearch"
              label="Can we use your anonymized medical information to assist with
              future research projects?"
              selectedValue={anonymizedMedicalResearch.granted || ''}
              onChange={(value) => setConsentsGranted({ value, type: 'anonymizedMedicalResearch' })}
              path="yesNoUnknown"
              orderSchema={['Y', 'N']}
            />
          ),
        },
        config,
      })}
    </Container>
  )
}

export { Consent }
