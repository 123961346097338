import { Box, Checkbox, FormControl, FormControlLabel } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson, renderContent, subQuestions } from '../../utils/helpers'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import { SubQuestionLabel } from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

function RelativeCardiacDevice({ personId, personLabel }) {
  const { setPersonConditions, removePersonConditions, setPersonConditionTreatments } = useActions()
  const heartProblem =
    useSelector((state) => {
      const conditions = get(state, [...pathToPerson(personId), 'conditions']) || []

      return conditions.find(({ id }) => id === 'heartProblems')
    }) || {}
  const baseConfig =
    useSelector((state) =>
      get(state, [
        'configuration',
        'sectionConfig',
        'heart-health-section',
        'subSectionConfig',
        'relatives-cardiac-device',
        'questions',
      ]),
    ) || []
  const config = subQuestions({ config: baseConfig, key: 'relatives-cardiac-device' })
  const { notes = '', ageAtTreatmentPerformed = '' } =
    heartProblem.treatments?.find(({ type }) => type === 'icd') || {}
  const isChecked = heartProblem.isPresent === 'Y' || false

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          {renderContent({
            renderMap: {
              cardiacDeviceType: (
                <TextFieldQuestion
                  key="cdt"
                  label="What type of device was received?"
                  value={notes}
                  handleChange={(value) => {
                    setPersonConditionTreatments({
                      id: personId,
                      conditionId: 'heartProblems',
                      treatmentType: 'icd',
                      isPerformed: 'Y',
                      value: { notes: value },
                    })
                  }}
                  subQuestion
                  fullWidth
                />
              ),
              cardiacDeviceDate: (
                <FormControl key="cdd">
                  <SubQuestionLabel>
                    Approximate age when they received the device?
                  </SubQuestionLabel>
                  <IntegerDetails
                    value={ageAtTreatmentPerformed}
                    onChange={(value) =>
                      setPersonConditionTreatments({
                        id: personId,
                        conditionId: 'heartProblems',
                        treatmentType: 'icd',
                        isPerformed: 'Y',
                        value: { ageAtTreatmentPerformed: value },
                      })
                    }
                  />
                </FormControl>
              ),
            },
            config,
          })}
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox({ target: { checked } }) {
    // on checkbox selected
    if (checked) {
      setPersonConditions({ id: personId, conditionId: 'heartProblems' })
      setPersonConditionTreatments({
        id: personId,
        conditionId: 'heartProblems',
        treatmentType: 'icd',
        isPerformed: 'Y',
      })

      return
    }
    // on checkbox deselected
    removePersonConditions({
      id: personId,
      conditionId: 'heartProblems',
    })
  }
}

export default RelativeCardiacDevice
