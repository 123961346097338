import AssignmentIcon from '@mui/icons-material/Assignment'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import { Alert, AppBar, Box, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Controls from './Controls'

const getIcon = (icon) => {
  if (icon === 'person') return <PersonIcon />
  if (icon === 'people') return <PeopleAltIcon />
  if (icon === 'list') return <FormatListNumberedIcon />
  if (icon === 'notes') return <AssignmentIcon />
  if (icon === 'completed') return <TaskAltIcon />
  if (icon === 'heart') return <VolunteerActivismIcon />

  return null
}

// Should not use forwardRef because it is for the virtualDOM element.
const CurrentStep = ({
  icon,
  navTitle,
  currentStep,
  handleMovePrevious,
  canMovePrevious,
  isLastStep,
  saveAndMoveToNext,
  setSubmitWarning,
  btnLabels,
  loadingControls,
  scrollRef,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const paddingX = isMobile ? 2 : 10
  const isRespondent = useSelector(({ questionnaire }) => !!questionnaire.respondent?.person)
  const paddingTop = () => {
    if (isMobile) {
      return isRespondent ? 2 : 10
    } else {
      return isMobile ? 12 : 4
    }
  }

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.white.main,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1',
        overflowY: 'auto',
        padding: 0,
      }}
      ref={scrollRef}
    >
      <AppBar elevation={2}>
        <Toolbar>
          {getIcon(icon)}
          <Typography variant="h2">{navTitle}</Typography>
        </Toolbar>
      </AppBar>
      {isRespondent ? (
        <Alert
          variant="outlined"
          severity="info"
          sx={{ mx: paddingX, mt: isMobile ? 12 : 4, mb: 1 }}
        >
          If you are a family member filling this out on behalf of someone else, please remember to
          include your details in all relevant family sections
        </Alert>
      ) : null}
      <Box
        pt={paddingTop()}
        pb={isMobile ? 20 : 4}
        px={paddingX}
        sx={{
          border: 'none',
          maxWidth: '1100px',
          flexGrow: 1,
        }}
        component="section"
        aria-label="current step content"
      >
        {currentStep}
      </Box>
      <Controls
        demoMode={false} // change to true for testing
        key="controls"
        handleMovePrevious={handleMovePrevious}
        canMovePrevious={canMovePrevious}
        isLastStep={isLastStep}
        saveAndMoveToNext={saveAndMoveToNext}
        setSubmitWarning={setSubmitWarning}
        btnLabels={btnLabels}
        loadingControls={loadingControls}
      />
    </Box>
  )
}

export default CurrentStep
