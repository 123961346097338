import React from 'react'
import RemoveLoadingHook from '../hooks/RemoveLoadingHook'
import InfoDisplay from '../widgets/InfoDisplay'

function Logout({ history }) {
  RemoveLoadingHook('UserLogout')

  return (
    <InfoDisplay
      title={history.location.state?.message || 'You are not in the right page.'}
      alert={
        'To log in again,' +
        ' please paste the link provided to you by your service provider in the url bar.'
      }
      color="primary"
    />
  )
}

export default Logout
