import { FormControl, FormLabel } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import IntegerDetails from '../../widgets/IntegerDetails'

const MenstrualPeriod = ({ id }) => {
  const localization = useLocalization()
  const ageAtMenarche =
    useSelector((state) => get(state, [...pathToPerson(id), 'cancerRiskData', 'ageAtMenarche'])) ||
    ''
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const { setPersonAgeAtMenarche } = useActions()
  const label = getLabel(localization, PersonFieldPaths.AGE_AT_MENARCHE, 'patient')

  return sex === 'F' ? (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <IntegerDetails
        label={label}
        value={ageAtMenarche}
        onChange={(value) => {
          if (value !== ageAtMenarche) {
            setPersonAgeAtMenarche({ id, value })
          }
        }}
      />
    </FormControl>
  ) : null
}

export default MenstrualPeriod
