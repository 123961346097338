import { Typography } from '@mui/material'
import React from 'react'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'

const Conclusion = () => {
  RemoveLoadingHook()

  return (
    <>
      <Typography>
        Please click Submit questionnaire when you are complete. The information you have provided
        will guide us in your assessment.
        <br />
        <br />
        Once the questionnaire is submitted, you will not be able to make any changes to your
        responses. If you have any questions about the information in this questionnaire, please
        speak with your genetics provider.
      </Typography>
    </>
  )
}

export { Conclusion }
