import React from 'react'
import Chooser from './Chooser.jsx'
import FollowUpQuestions from './FollowUpQuestions.jsx'

function ConditionalSection({
  children,
  conditionState,
  cleanUpChildQuestions,
  label,
  onConditionStateChange,
  orderSchema,
  showWhenCondition,
  fullWidth = false,
}) {
  const renderChildren = () => {
    let _children = children
    // remove null child elements from elements
    if (Array.isArray(children)) {
      _children = children.filter((item) => item)
      // check for empty array
      if (!_children.length) return null
    }

    return showWhenCondition === conditionState && _children ? (
      <FollowUpQuestions>{_children}</FollowUpQuestions>
    ) : null
  }

  const onParentQuestionChange = (e) => {
    onConditionStateChange(e)
    if (e !== showWhenCondition) {
      cleanUpChildQuestions()
    }
  }

  return (
    <>
      <Chooser
        label={label}
        onChange={(e) => onParentQuestionChange(e)}
        selectedValue={conditionState}
        orderSchema={orderSchema}
        path={'yesNoUnknown'}
        fullWidth={fullWidth}
      />
      {renderChildren()}
    </>
  )
}

export default ConditionalSection
