import errors from '../../data/errors.json'
import history from '../../utils/history'

/**
 * Checks response for BE error and returns the parsed JSON response if ok
 * @param {Response} response - The response object to check.
 * @returns {Promise<any>} - A promise that resolves to the parsed JSON response.
 * @throws {Error} - If we get a network error, throw Error with the status and cause message.
 */
export const checkResponseForError = async (response) => {
  if (!response?.ok) {
    const error = await response.json()
    throw new Error(error.status, { cause: error.message })
  }

  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json()
  }
}

/**
 * Navigates to the error screen based on the error type and status.
 * @param {Object} options - The options object containing fetchType, status, and cause.
 * @param {string} options.fetchType - The type of fetch operation (e.g., login, submission).
 * @param {string} options.status - The status code of the error response.
 * @param {string} options.cause - The cause of the error.
 */
export const navigateToErrorScreen = ({ fetchType = '', status = '', cause = '' }) => {
  let errMessages = []

  switch (true) {
    case !window.navigator.onLine:
      errMessages = errors.NETWORK_DISCONNECT
      break
    case fetchType === 'login' && status === '401':
      errMessages = [cause, ...errors.CANCELLED]
      break
    case fetchType === 'login' && status === '409':
      errMessages = [cause, ...errors.SUBMITTED]
      break
    case status === '401' || status === '403':
      errMessages = [cause, ...errors.SESSION_ERROR_MESSAGE]
      break
    // Any string message (not number type statusCode) from the network.
    case status !== '*' && isNaN(status):
      errMessages = [status]
      break
    // No message available
    default:
      errMessages = errors.UNEXPECTED
      break
  }

  // In the submission, we should not switch the page
  // because the user should know the current error status at the same page.
  if (fetchType !== 'submission') {
    history.push('/message', { errMessages })
  }
}
