import AddIcon from '@mui/icons-material/Add'
import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
  useMediaQuery,
} from '@mui/material'
import React, { useState } from 'react'
import { useActions } from '../../hooks/useActions'
import { PERSON_CONDITIONS } from '../../utils/ConditionTypes'
import { renderContent } from '../../utils/helpers'
import IntegerDetails from '../../widgets/IntegerDetails'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

function HeartConditionDetails({ treatment, setTreatment, qualifier, setQualifier, config }) {
  const { treatmentLocation = '', patientAddress = '' } = treatment
  const { type = '' } = qualifier

  return renderContent({
    renderMap: {
      heartConditionsAge: (
        <FormControl key="hcAge">
          <FormLabel>Age at diagnosis?</FormLabel>
          <IntegerDetails
            value={type}
            onChange={(value) => setQualifier({ label: 'Age at diagnosis', type: value })}
          />
        </FormControl>
      ),
      heartConditionHospital: (
        <TextFieldQuestion
          key="hcHos"
          label="Hospital where the treatment was given?"
          value={treatmentLocation}
          handleChange={(value) => setTreatment({ ...treatment, treatmentLocation: value })}
        />
      ),
      heartConditionAddress: (
        <TextFieldQuestion
          key="hcAdd"
          label="Address during treatment? (Enter town/city if unknown)"
          value={patientAddress}
          handleChange={(value) => setTreatment({ ...treatment, patientAddress: value })}
        />
      ),
    },
    config,
  })
}

function HeartConditionsModal({ personId, open, setOpen, config }) {
  const { setPersonConditions, setPersonConditionTreatments, setPersonConditionQualifiers } =
    useActions()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [condition, setCondition] = useState({})
  const [treatment, setTreatment] = useState({})
  const [qualifier, setQualifier] = useState({})

  const heartConditions = PERSON_CONDITIONS.filter(({ type }) => type === 'heartConditions')

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Add Heart Condition</DialogTitle>
      <DialogContent sx={{ minWidth: isMobile ? '80vw' : '35vw' }}>
        <Container>
          <FormLabel label="Search for Heart Conditions">
            <FormControl fullWidth>
              <Autocomplete
                sx={{ pt: 1 }}
                freeSolo
                value={condition.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value}
                options={conditionsToOption(heartConditions)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option?.label || ''}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search..."
                    onKeyDown={(e) => {
                      // Prevents a crash where an undefined object is added to the list
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.stopPropagation()
                      }
                    }}
                  />
                )}
                onChange={(e, newValue) => {
                  if (!newValue) {
                    setCondition({})
                    setTreatment({})

                    return
                  }
                  setCondition({ id: newValue.value, label: newValue.label })
                }}
              />
            </FormControl>
          </FormLabel>
          <HeartConditionDetails
            treatment={treatment}
            setTreatment={setTreatment}
            qualifier={qualifier}
            setQualifier={setQualifier}
            config={config}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          endIcon={<AddIcon />}
          onClick={() => addDiagnosis()}
          disabled={!('id' in condition)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )

  function conditionsToOption(conditions) {
    return conditions.map(({ id, label }) => ({
      value: id,
      label,
    }))
  }
  function handleClose() {
    setOpen(false)
    setCondition({})
    setTreatment({})
    setQualifier({})
  }
  function addDiagnosis() {
    setPersonConditions({ id: personId, conditionId: condition.id })
    if (Object.keys(treatment).length) {
      setPersonConditionTreatments({
        id: personId,
        conditionId: condition.id,
        treatmentType: 'other',
        isPerformed: 'Y',
        value: treatment,
      })
    }
    if (Object.keys(qualifier).length) {
      setPersonConditionQualifiers({
        id: personId,
        conditionId: condition.id,
        label: qualifier.label,
        type: qualifier.type,
      })
    }
    handleClose()
  }
}

export default HeartConditionsModal
