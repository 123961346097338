import { useContext, useLayoutEffect } from 'react'
import Context from '../utils/context/Context'

const RemoveLoadingHook = (unAuthPage = undefined) => {
  const { setLoading, loading = {}, setAuthState } = useContext(Context)

  useLayoutEffect(() => {
    // When Message and UserLogout page parks, auth automatically is false.
    // We do not need to add setAuthState every time it has an error or logout anymore.
    if (unAuthPage) {
      setAuthState(() => ({
        isAuthenticated: false,
        loggingIn: true,
      }))
    }

    // Logically loading / button disabled should be done
    // after the step parks in the browser. Then, the double-
    // click issue in the control buttons disappears.
    if (Object.keys(loading).length) {
      setLoading(() => ({}))
    }
  }, [])

  return true
}

export default RemoveLoadingHook
