import { createFilterOptions, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import uuidv4 from 'uuid'
import { useActions } from '../../hooks/useActions'
import { getPersonLabel } from '../../utils/helpers'
import SearchSelect from '../../widgets/SearchSelect'

const filter = createFilterOptions()

const RelativeSelect = () => {
  const { excludedPersons = [], persons } = useSelector(({ questionnaire }) => ({
    excludedPersons: questionnaire.consents?.find((consent) => consent.type === 'share')
      ?.excludedPersons,
    persons: questionnaire.persons,
  }))
  const { setConsentsExcludedPersons } = useActions()
  const relatives =
    Object.values(persons).filter(
      (person) =>
        person.relationshipToProband !== 'proband' &&
        person.relationshipToProband !== 'probandsPartner',
    ) || []

  return (
    <SearchSelect
      label={
        <Typography>
          Specify any relatives your information should <b>NOT</b> be shared with
        </Typography>
      }
      multiple
      inputLabel="Search or specify other relatives..."
      value={convertPersonOptions(excludedPersons)}
      options={convertPersonOptions(relatives)}
      onChange={(_, newValues) => {
        const _persons = newValues.map(({ value: id, name, relationship }) => ({
          id,
          name: { firstName: name },
          relationshipToProband: relationship,
        }))
        setConsentsExcludedPersons({ value: _persons, type: 'share' })
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue?.toUpperCase() === option.name.toUpperCase(),
        )
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            value: uuidv4(),
            name: inputValue,
            relationship: 'userDefined',
            label: `Add "${inputValue}" to list`,
          })
        }

        return filtered
      }}
    />
  )

  function convertPersonOptions(persons) {
    return persons.map((person) => {
      const { name, relationship } = getPersonLabel(person)

      return {
        label: name ? `${name} (${relationship})` : relationship,
        name,
        relationship: person.relationshipToProband,
        value: person.id,
      }
    })
  }
}

export default RelativeSelect
