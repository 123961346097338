import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'

const MantleRadiation = ({ id }) => {
  const localization = useLocalization()
  const { setPersonTreatments } = useActions()
  const selectedValue = useSelector((state) =>
    get(state, [...pathToPerson(id), 'treatments', 0, 'isPerformed']),
  )
  const hodgkinLymphoma = useSelector((state) => {
    const cancers = get(state, [...pathToPerson(id), 'cancers']) || []

    return cancers.find(({ id }) => id === 'HP:0012189')
  })

  return hodgkinLymphoma ? (
    <Chooser
      orderSchema={['Y', 'N', 'U']}
      path="yesNoUnknown"
      key="MantleRadiation"
      label={getLabel(localization, PersonFieldPaths.TREATMENT_MANTLE_RADIATION, 'person')}
      selectedValue={selectedValue}
      onChange={(value) => {
        if (value !== selectedValue) {
          setPersonTreatments({ id, type: 'mantleRadiation', value })
        }
      }}
    />
  ) : null
}

export default MantleRadiation
