import { createTheme } from '@mui/material'

const mui = createTheme({})

const base = {
  palette: {
    primary: {
      main: '#006699',
      light: '#EDF3F6',
      dark: '#115B91',
      transparent50: 'rgba(0, 102, 153, 50%)',
      bg: 'rgba(0, 102, 153, 5%)',
      bgDark: 'rgba(19, 124, 189, 15%)',
    },
    error: {
      main: '#B23C3C',
      bg: 'rgba(178, 60, 60, 10%)',
    },
    warning: {
      main: 'rgb(150, 103, 0)',
      light: 'rgb(255, 228, 168)',
      dark: 'rgb(108, 74, 0)',
      bg: 'rgba(255, 175, 0, 10%)',
    },
    white: {
      main: '#fff',
      transparent: 'rgb(235 235 235 / 10%)',
    },
    text: {
      main: 'rgb(6 26 59)',
    },
  },
  shadows: [
    ...mui.shadows.map((shadow, i) => {
      if (i === 1) {
        return '0 0 5px rgba(0,0,0,0.3)'
      } else if (i === 2) {
        return '0 0 10px 0 rgba(0,0,0,0.2)'
      } else if (i === 3) {
        return '0 0 15px 0 rgba(0,0,0,0.2)'
      } else {
        return shadow
      }
    }),
  ],
}

const {
  palette: { primary, white, text },
} = base

export const theme = createTheme({
  ...base,
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1: {
      fontSize: '1.075rem',
      color: text,
    },
    body2: {
      fontWeight: '500',
      fontSize: '1rem',
      lineHeight: 1.4,
      color: text,
    },
    subtitle1: {
      fontWeight: '500',
      fontSize: '1rem',
      lineHeight: 1.4,
      color: text,
    },
    subtitle2: {
      fontSize: '0.75rem',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: '700',
      color: text,
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: '500',
      color: primary.main,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: base.shadows[2],
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '11px 17px',
          lineHeight: 1.2,
          fontSize: '1.075rem',
        },
        outlined: {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: primary.bgDark,
          },
        },
        outlinedSizeSmall: {
          padding: '9px 15px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedWarning: {
          color: white.main,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        colorPrimary: {
          backgroundColor: primary.bg,
          '&:hover': {
            backgroundColor: primary.bg,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: mui.spacing(3),
          padding: 0,
          [mui.breakpoints.up('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          zIndex: 10,
          width: '24vw',
          maxWidth: '320px',
          '&::-webkit-scrollbar, *::-webkit-scrollbar': {
            display: 'none',
          },
        },
        paper: {
          position: 'initial',
          border: 'none',
          boxShadow: base.shadows[2],
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          gap: mui.spacing(1),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: '500',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          margin: '10px 20px',
          borderRadius: '5px',
          '& .MuiListItemText-primary': {
            fontWeight: '400',
            fontSize: '1rem',
          },
          '&.Mui-selected': {
            backgroundColor: primary.bg,
            '& .MuiListItemText-primary': {
              fontWeight: '700',
              color: primary.main,
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: 'fixed',
          background: white.main,
          color: primary.main,
          zIndex: 5,
          [mui.breakpoints.up('md')]: {
            boxShadow: 'none',
            position: 'static',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          gap: mui.spacing(2),
          minHeight: mui.spacing(8),
          [mui.breakpoints.up('md')]: {
            padding: `${mui.spacing(4)} ${mui.spacing(10)} 0`,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: {
          visibility: 'visible',
        },
        tag: {
          backgroundColor: primary.bg,
          color: primary.main,
          fontWeight: 500,
          '& .MuiChip-deleteIcon': {
            color: primary.main,
          },
          '& .MuiChip-deleteIcon:hover': {
            color: primary.dark,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: -3,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 400,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: white.main,
        },
        input: {
          padding: '13px 15px',
        },
        notchedOutline: {
          border: `1px solid ${primary.transparent50}`,
          borderRadius: 5,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: mui.spacing(3),
          paddingTop: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          [mui.breakpoints.up('sm')]: {
            minWidth: 500,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: mui.spacing(3),
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingLeft: mui.spacing(3),
          paddingRight: mui.spacing(3),
          backgroundColor: primary.bg,
        },
        title: {
          fontWeight: 500,
          color: primary.main,
        },
        action: {
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          gap: 2,
          margin: 0,
          marginTop: 6,
        },
      },
    },
  },
})
