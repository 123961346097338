import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import HelpIcon from '@mui/icons-material/Help'
import { Box, Button } from '@mui/material'
import React from 'react'

export default function ButtonGroup({ disabled, onChange, selectedValue, options }) {
  const getIcon = (value) => {
    switch (value) {
      case 'Y':
        return <CheckIcon />
      case 'N':
        return <CloseIcon />
      case 'U':
        return <HelpIcon />
      default:
        break
    }
  }
  const renderOptions = () => {
    return options.map((property, index) => {
      const isSelected = selectedValue === property[0]

      return (
        <Button
          key={'button-group-' + property[0]}
          startIcon={getIcon(property[0])}
          checked={isSelected}
          disabled={Array.isArray(disabled) ? disabled[index] : disabled}
          onClick={() => onChange(property[0])}
          sx={
            isSelected
              ? {
                  backgroundColor: (theme) => theme.palette.primary.bgDark,
                }
              : null
          }
        >
          {property[1]}
        </Button>
      )
    })
  }

  return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>{renderOptions()}</Box>
}
