import { Auth } from './Auth'
import { Main } from './Main'
import { checkResponseForError, navigateToErrorScreen } from './utils/errors'

export class Update extends Main {
  static baseUrl = this.getRoot()

  static async updateQuestionnaire({ questionnaire }) {
    const url = `${this.baseUrl}/questionnaire/current-session`

    try {
      const previousQuestionnaire = await Auth.getSession()

      previousQuestionnaire.pedigree = questionnaire
      previousQuestionnaire.notes = questionnaire.notes
      previousQuestionnaire.additionalHealthProblems = questionnaire.additionalHealthProblems
      previousQuestionnaire.relevantInfo = questionnaire.relevantInfo
      previousQuestionnaire.respondent = questionnaire.respondent
      previousQuestionnaire.isMetricMeasurementSystem = questionnaire.isMetricMeasurementSystem
      previousQuestionnaire.additionalRelativeInfos = questionnaire.additionalRelativeInfos
      previousQuestionnaire.consents = questionnaire.consents

      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(previousQuestionnaire),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': sessionStorage.getItem('csrfToken'),
        },
      })

      return await checkResponseForError(response)
    } catch (err) {
      const { message: status = '', cause = '' } = err

      navigateToErrorScreen({
        fetchType: 'updateQuestionnaire',
        status,
        cause,
      })
    }
  }

  static async submitQuestionnaire() {
    const url = `${this.baseUrl}/questionnaire/current-session/submit`

    try {
      const submitted = await fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': sessionStorage.getItem('csrfToken'),
        },
      })

      return await checkResponseForError(submitted)
    } catch (err) {
      const { message: status = '', cause = '' } = err

      navigateToErrorScreen({
        fetchType: 'submission',
        status,
        cause,
      })
    }
  }

  static async updateMetrics({ metricState }) {
    const url = `${this.baseUrl}/questionnaire-metrics`
    // Error control is not required.
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ data: JSON.stringify(metricState) }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': sessionStorage.getItem('csrfToken'),
      },
    })
  }
}
