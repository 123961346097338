import { Alert } from '@mui/material'
import get from 'lodash/get'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useFamilyHelperHook } from '../../hooks/useFamilyHelperHook'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import ConditionalSection from '../../widgets/ConditionalSection'
import DialogWindow from '../../widgets/DialogWindow'

const Adoption = ({ id, targetPerson }) => {
  const { setPersonAdopted, removePerson } = useActions()
  const localization = useLocalization()
  const { getSiblingIds } = useFamilyHelperHook({ id })
  const adopted = useSelector((state) => get(state, [...pathToPerson(id), 'adopted'])) || ''
  const [dialogOpen, setDialogOpen] = useState(false)

  let label
  switch (targetPerson) {
    case 'mother':
      label = getLabel(localization, PersonFieldPaths.IS_MOTHER_ADOPTED, 'patient')
      break
    case 'father':
      label = getLabel(localization, PersonFieldPaths.IS_FATHER_ADOPTED, 'patient')
      break
    default:
      label = getLabel(localization, PersonFieldPaths.IS_ADOPTED, 'patient')
      break
  }

  return (
    <>
      <ConditionalSection
        label={label}
        conditionState={adopted}
        onConditionStateChange={handleChange}
        orderSchema={['Y', 'N']}
        cleanUpChildQuestions={() => null}
      />
      {targetPerson === 'proband' && adopted === 'Y' && (
        <Alert severity="info">
          The following questions relate to the health history of your biological&nbsp; relatives.
          You can skip the questions if you do not have this information.{' '}
        </Alert>
      )}
      <DialogWindow
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title="Remove Person's Siblings"
        text="Please note that this will also remove all of this 
          person's siblings. Please confirm your choice."
        color="warning"
        handleConfirm={confirmRemovePersonEntry}
      />
    </>
  )

  function handleChange(value) {
    if (value === adopted) return

    if (targetPerson !== 'proband' && value === 'Y') {
      setDialogOpen(true)
    } else {
      setPersonAdopted({ id, value })
    }
  }

  function confirmRemovePersonEntry() {
    getSiblingIds().forEach((siblingId) => {
      removePerson({ personId: siblingId })
    })
    setPersonAdopted({ id, value: 'Y' })
    setDialogOpen(false)
  }
}

export default Adoption
