import { Box, Checkbox, FormControlLabel } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization, useProband } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import {
  PersonProperties as properties,
  PersonFieldPaths as paths,
} from '../../utils/QuestionnaireStateManager'
import CancerHistory from '../../widgets/CancerHistory'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'

export default function DiagnosedCancer({ personId, personLabel, lastItemUnchecked }) {
  const { setPersonPropertiesIsPresent, removePersonPropertiesList, setPersonCancers } =
    useActions()
  const localization = useLocalization()
  const proband = useProband()
  const hasCancer = useSelector((state) => {
    const personProperties = get(state, [...pathToPerson(personId), 'properties']) || []

    return personProperties.find(({ type }) => type === properties.HAS_CANCER)
  })
  const relativeCancers =
    useSelector((state) => get(state, [...pathToPerson(personId), 'cancers'])) || []

  const config = useSelector((state) =>
    get(state, [
      'configuration',
      'sectionConfig',
      'cancer-related-question',
      'subSectionConfig',
      'diagnosed-with-cancer',
      'questions',
    ]),
  )
  const isChecked = !!hasCancer

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <CancerHistory
            label={getLabel(localization, paths.TYPES_OF_CANCER, 'person')}
            personId={personId}
            config={config}
          />
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    const { properties: probandProperties = [] } = proband
    const hasProbandProperty =
      probandProperties.find(({ type }) => type === properties.HAS_RELATIVE_HAS_CANCER)
        ?.isPresent !== 'Y'

    // on checkbox selected
    if (e.target.checked) {
      if (hasProbandProperty) {
        setPersonPropertiesIsPresent({
          id: proband.id,
          type: properties.HAS_RELATIVE_HAS_CANCER,
          value: 'Y',
        })
      }
      setPersonPropertiesIsPresent({
        id: personId,
        type: properties.HAS_CANCER,
        value: 'Y',
      })

      return
    }

    // on checkbox deselected
    if (lastItemUnchecked()) {
      setPersonPropertiesIsPresent({
        id: proband.id,
        type: properties.HAS_RELATIVE_HAS_CANCER,
        value: 'N',
      })
    }
    removePersonPropertiesList({
      id: personId,
      type: properties.HAS_CANCER,
    })
    if (relativeCancers.length > 0) {
      setPersonCancers({
        id: personId,
        value: [],
      })
    }
  }
}
