import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import {
  PersonFieldPaths as paths,
  PersonProperties as properties,
} from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'

export default function RelativeGender({ personId, personLabel }) {
  const {
    setPersonGenderIdentity,
    removeGenderIdentity,
    setPersonPropertiesIsPresent,
    removePersonPropertiesList,
  } = useActions()
  const relative = useSelector(({ questionnaire }) => questionnaire.persons[personId])
  const sexLabel = { M: 'Male', F: 'Female' }
  const { sex = '', genderIdentity = '' } = relative
  const isChecked = !!relative.properties.find(
    (property) => property.type === properties.HAS_DIFFERENT_GENDER_IDENTITY,
  )

  return (
    <Box role="region">
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <Box>
            <Typography variant="body2" pb={1}>
              Sex assigned at birth:
            </Typography>
            <Typography variant="body1" fontWeight="700" color="primary">
              {sexLabel[sex]}
            </Typography>
          </Box>
          <Chooser
            label="Gender Identity"
            onChange={(value) => {
              setPersonGenderIdentity({ id: personId, value })
            }}
            selectedValue={genderIdentity}
            orderSchema={sex === 'M' ? ['F', 'nonBinary', 'O'] : ['M', 'nonBinary', 'O']}
            patient={'patient'}
            path={paths.GENDER_IDENTITY}
            subQuestion
          />
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    // on checkbox selected
    if (e.target.checked) {
      setPersonPropertiesIsPresent({
        id: personId,
        type: properties.HAS_DIFFERENT_GENDER_IDENTITY,
        value: 'Y',
      })

      return
    }
    // on checkbox deselected
    removeGenderIdentity({
      id: personId,
    })
    removePersonPropertiesList({
      id: personId,
      type: properties.HAS_DIFFERENT_GENDER_IDENTITY,
    })
  }
}
