import { Alert, Container, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import uuidv4 from 'uuid'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import { useLocalization, useShallowSelector } from '../../redux/reduxHooks.js'
import { getLabel, renderContent } from '../../utils/helpers'
import AncestrySelect from './AncestrySelect'
import AshkenaziAncestry from './AshkenaziAncestry.jsx'
import RelatedByBlood from './RelatedByBlood'

const Parents = () => {
  const { setParentsRelationships } = useActions()
  const localization = useLocalization()
  const config = useSelector(({ configuration }) => configuration.sectionConfig.parents.questions)
  const probandId = useSelector((state) => state.questionnaire.probandId)
  const persons = useShallowSelector((state) => state.questionnaire.persons) || {}
  const relationships = useShallowSelector((state) => state.questionnaire.relationships)

  const bothParentMembers = Object.values(persons).filter(
    ({ relationshipToProband }) =>
      relationshipToProband === 'probandsFather' || relationshipToProband === 'probandsMother',
  )

  RemoveLoadingHook()

  useEffect(() => {
    let parentPartnership = {
      id: uuidv4(),
      properties: [],
      type: 'partnership',
    }

    if (bothParentMembers.length < 2) {
      const [parent = undefined] = bothParentMembers
      const fatherId = parent?.sex === 'M' ? parent.id : uuidv4()
      const motherId = parent?.sex === 'F' ? parent.id : uuidv4()

      const parents = {
        [fatherId]: {
          id: fatherId,
          relationshipToProband: 'probandsFather',
          sex: 'M',
        },
        [motherId]: {
          id: motherId,
          relationshipToProband: 'probandsMother',
          sex: 'F',
        },
      }

      if (parent) {
        const id = parent.sex === 'M' ? fatherId : motherId

        delete parents[id]
      }

      const probandParentRelationship = Object.keys(parents).map((id) => {
        return {
          id: uuidv4(),
          properties: [],
          source: id,
          target: probandId,
          type: 'parent',
        }
      })

      parentPartnership = {
        ...parentPartnership,
        source: fatherId,
        target: motherId,
      }

      setParentsRelationships({
        parentsPerson: { ...persons, ...parents },
        parentsRelationship: [...relationships, ...probandParentRelationship, parentPartnership],
      })
    }

    if (bothParentMembers.length === 2) {
      const _bothParentMembers = [...bothParentMembers]

      _bothParentMembers.sort(({ sex: sex1 }, { sex: sex2 }) => sex2.localeCompare(sex1))

      const [father, mother] = _bothParentMembers

      parentPartnership = {
        ...parentPartnership,
        source: father.id,
        target: mother.id,
      }

      const hasPartnership = relationships.some(
        ({ type, source, target }) =>
          type === parentPartnership.type &&
          source === parentPartnership.source &&
          target === parentPartnership.target,
      )

      if (!hasPartnership) {
        setParentsRelationships({
          parentsRelationship: [...relationships, parentPartnership],
        })
      }
    }
  }, [])

  return (
    <Container>
      <Typography key="instructions" variant="body2">
        Please add one or more known countries or regions of ancestry on both sides of the family:
      </Typography>
      {renderContent({
        renderMap: {
          motherAncestry: <AncestrySelect key="ancestry-mother" side="maternal" />,
          fatherAncestry: <AncestrySelect key="ancestry-father" side="paternal" />,
        },
        config,
      })}
      <Alert key="info" severity="info">
        {getLabel(localization, 'ancestry.inherited.tooltip', 'patient')}
      </Alert>
      {renderContent({
        renderMap: {
          parentsConsang: (
            <RelatedByBlood
              key="pc"
              ancestryProperty="consanguineous"
              label={getLabel(localization, 'parents.consanguinity', 'patient')}
              subLabel={getLabel(localization, 'parents.consanguinity.related', 'patient')}
              config={config}
              probandId={probandId}
            />
          ),
          parentsAshkenazi: (
            <AshkenaziAncestry
              key="pa"
              probandId={probandId}
              label={getLabel(localization, 'patient.ancestry.ashkenaziJewish')}
            />
          ),
        },
        config,
      })}
    </Container>
  )
}

export { Parents }
