import { Typography } from '@mui/material'
import React from 'react'

/* eslint-disable no-undef */
const appVersion = process.env.REACT_APP_VERSION

const AppVersion = () => (
  <Typography sx={{ pb: 1.5, pl: 1.5, fontSize: 12, color: '#adadad', letterSpacing: '0.3px' }}>
    {'v' + appVersion}
  </Typography>
)

export default AppVersion
