import {
  getPerson,
  setPersonListObjectType,
} from '../../../slices/questionnaireSlice/utils/helpers'

export default {
  /**
   * Set person mantle radiation property in list person.treatments
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: string }}
   * )}
   */
  setPersonTreatments: (state, action) => {
    const { type, value } = action.payload
    const newTreatment = { type, isPerformed: value }

    setPersonListObjectType(state, action, type, 'treatments', newTreatment)
  },
  /**
   * Remove person mantle radiation property in list person.treatments
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string }}
   * )}
   */
  removePersonTreatments: (state, action) => {
    const { type } = action.payload
    const person = getPerson(state, action)
    if (!person.treatments) throw new Error(`treatments list does not exist`)

    person.treatments = person.treatments.filter((item) => item.type !== type)
  },
}
