import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'

import ConditionalSection from '../../widgets/ConditionalSection'

const Cancer = ({ id, patient, children }) => {
  const localization = useLocalization()
  const { setPersonPropertiesIsPresent, removePersonProperty, removePersonTreatments } =
    useActions()
  const cancers = useSelector((state) => get(state, [...pathToPerson(id), 'cancers'])) || []
  const { isPresent = '' } =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(id), 'properties']) || []

      return properties.find(({ type }) => type === 'hasCancer')
    }) || {}

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      label={getLabel(localization, paths.DIAGNOSED_WITH_CANCER, patient)}
      conditionState={isPresent}
      onConditionStateChange={(value) => {
        if (value !== isPresent) {
          setPersonPropertiesIsPresent({ id, type: 'hasCancer', value })
        }
      }}
      cleanUpChildQuestions={() => {
        if (cancers.length > 0) {
          removePersonProperty({ id, type: 'cancers' })
          removePersonTreatments({ id, type: 'mantleRadiation' })
        }
      }}
    >
      {children}
    </ConditionalSection>
  )
}

export default Cancer
