import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { subQuestions } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import HeartConditionsList from './HeartConditionList'
import HeartConditionsModal from './HeartConditionsModal'

function HeartConditions({ config, id }) {
  const { setPersonConditions, setPersonConditionIsPresent, removePersonHeartConditions } =
    useActions()
  const { conditions = [] } = useSelector(({ questionnaire }) => ({
    conditions: questionnaire.persons[id].conditions,
  }))
  const [modalOpened, setModalOpened] = useState(false)
  const hasHeartConditions = conditions.find(({ id }) => id === 'heartConditions')
  const heartConditions = conditions.filter(({ type }) => type === 'heartConditions')
  const heartConditionConfig = subQuestions({ config, key: 'heart-conditions' })

  return (
    <ConditionalSection
      label="Have you been diagnosed with any heart conditions?"
      conditionState={hasHeartConditions?.isPresent || ''}
      onConditionStateChange={(value) => {
        if (!hasHeartConditions) {
          setPersonConditions({ id, conditionId: 'heartConditions' })
        }
        if (value !== hasHeartConditions?.isPresent) {
          setPersonConditionIsPresent({ id, conditionId: 'heartConditions', value })
        }
      }}
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => {
        if (heartConditions.length > 0) {
          removePersonHeartConditions({ id })
        }
      }}
    >
      <Button startIcon={<AddIcon />} onClick={() => setModalOpened(true)}>
        Add Heart Condition
      </Button>
      <HeartConditionsModal
        open={modalOpened}
        setOpen={setModalOpened}
        personId={id}
        config={heartConditionConfig}
      />
      <HeartConditionsList id={id} heartConditions={heartConditions} />
    </ConditionalSection>
  )
}

export default HeartConditions
