import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { renderContent } from '../../utils/helpers'
import HeartConditions from './HeartConditions'
import HeartProblems from './HeartProblems'
import HeartSymptoms from './HeartSymptoms'

const HeartHealthHistory = ({ config }) => {
  const { probandId } = useSelector(({ questionnaire }) => ({
    probandId: questionnaire.probandId,
  }))
  const componentProps = { config, id: probandId }
  const renderMap = {
    heartSymptoms: <HeartSymptoms key="heartSymptoms" {...componentProps} />,
    heartProblems: <HeartProblems key="heartProblems" {...componentProps} />,
    heartConditions: <HeartConditions key="heartConditions" {...componentProps} />,
  }
  const content = []
  renderContent({ renderMap, config, content })

  RemoveLoadingHook()

  return <Container>{content}</Container>
}

export { HeartHealthHistory }
