import React, { useEffect, useContext } from 'react'
import { navigateToErrorScreen } from '../api/utils/errors'
import Context from '../utils/context/Context'
import Spinner from '../widgets/Spinner'

const Loading = ({ history }) => {
  const { setToken } = useContext(Context)

  useEffect(() => {
    try {
      if (history.location.search) {
        const token = history.location.search.split(/\/?accessToken=/)[1]
        setToken(decodeURIComponent(token))
      }
      history.push('/auth')
    } catch (error) {
      navigateToErrorScreen({
        fetchType: 'login',
        status: 'The link format is incorrect, please try to copy and paste the link again',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spinner message="Loading the Application..." />
}

export default Loading
