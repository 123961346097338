import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import ConditionalSection from '../../widgets/ConditionalSection'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const BreastMammogram = ({ id, patient, config }) => {
  const { setPersonBreastMammogramStatus } = useActions()
  const localization = useLocalization()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex']))
  const breastMammogram =
    useSelector((state) => {
      const diagnosticTests = get(state, [...pathToPerson(id), 'diagnosticTests']) || []

      return diagnosticTests.find(({ type }) => type === 'breastMammogram')
    }) || {}

  return sex === 'F' ? (
    <ConditionalSection
      label={getLabel(localization, paths.BREAST_MAMMOGRAM_LABEL, patient)}
      conditionState={breastMammogram.isPerformed}
      showWhenCondition="Y"
      onConditionStateChange={(value) => {
        if (!breastMammogram.type) {
          setPersonBreastMammogramStatus({
            id,
            type: 'type',
            value: 'breastMammogram',
          })
        }
        if (breastMammogram.isPerformed !== value) {
          setPersonBreastMammogramStatus({ id, type: 'isPerformed', value })
        }
      }}
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => {
        setPersonBreastMammogramStatus({ id, type: 'lastPerformedDate', value: '' })
        setPersonBreastMammogramStatus({ id, type: 'performedAtLocation', value: '' })
      }}
    >
      {renderContent({
        renderMap: {
          mammogramLastDate: (
            <TextFieldQuestion
              key="bmd"
              label={getLabel(localization, paths.DIAGNOSTIC_TEST_LAST_PERFORMED, patient)}
              handleChange={(value) =>
                setPersonBreastMammogramStatus({
                  id,
                  type: 'lastPerformedDate',
                  value,
                })
              }
              value={breastMammogram.lastPerformedDate ?? ''}
              subQuestion
            />
          ),
        },
        config,
      })}
      {renderContent({
        renderMap: {
          mammogramLocation: (
            <TextFieldQuestion
              key="bmh"
              label={getLabel(localization, paths.DIAGNOSTIC_TEST_PERFORMED_LOCATION, patient)}
              handleChange={(value) =>
                setPersonBreastMammogramStatus({
                  id,
                  type: 'performedAtLocation',
                  value,
                })
              }
              value={breastMammogram.performedAtLocation ?? ''}
              subQuestion
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  ) : null
}

export default BreastMammogram
