import { Main } from './Main'
import { checkResponseForError, navigateToErrorScreen } from './utils/errors'

export class Auth extends Main {
  static baseUrl = this.getRoot()

  static async login({
    accessToken = '',
    success,
    dateOfBirth = { day: '', month: '', year: '' },
    failure = undefined,
    redirect = undefined,
  }) {
    const url = `${this.baseUrl}/login`

    try {
      const response = await fetch(url, {
        method: 'POST',
        url,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessToken,
          dateOfBirth,
        }),
      })

      await checkResponseForError(response)
      // Store CSRF Token in Session Storage
      const csrfToken = response.headers.get('X-CSRF-Token')
      if (csrfToken) {
        sessionStorage.setItem('csrfToken', csrfToken)
      }
      // Control SessionTimeout
      if (!redirect && success) {
        success()
      }
      // Login with token
      if (redirect && (await success())) {
        await redirect()
      }
    } catch (err) {
      const { message: status = '', cause = '' } = err

      if (failure && !failure(status)) {
        return
      }

      navigateToErrorScreen({
        fetchType: 'login',
        status,
        cause,
      })
    }
  }

  static async logout({ addToast = undefined } = {}) {
    try {
      const response = await fetch('/logout', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': sessionStorage.getItem('csrfToken'),
        },
      })
      await checkResponseForError(response)

      if (addToast) {
        addToast('success', 'info', 'Successfully Logged Out')
      }
    } catch (err) {
      const { message: status = '', cause = '' } = err

      if (addToast) {
        addToast('error', 'error', err.message)
      }

      navigateToErrorScreen({
        fetchType: 'logout',
        status,
        cause,
      })
    }
  }

  static async getSession() {
    const url = `${this.baseUrl}/questionnaire/current-session`

    try {
      const response = await fetch(url, { method: 'GET' })

      return await checkResponseForError(response)
    } catch (err) {
      const { message: status = '', cause = '' } = err
      navigateToErrorScreen({
        fetchType: 'getSession',
        status,
        cause,
      })
    }
  }
}
