import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { pathToPerson } from '../utils/helpers'
import Chooser from './Chooser'

const SexAssignedAtBirth = ({ id, label = undefined, clearSexSpecificQuestions = () => {} }) => {
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex']))
  const { setPersonSex } = useActions()

  return (
    <Chooser
      label={label || 'Sex assigned at birth'}
      orderSchema={['M', 'F']}
      path="sex"
      onChange={handleClick}
      selectedValue={sex}
    />
  )

  /**
   * set person sex on button click
   */
  function handleClick(value) {
    // Need to block the function when the user clicks the same sex in the buttons.
    if (value !== sex) {
      setPersonSex({ id, value })
      clearSexSpecificQuestions(value)
    }
  }
}

export default SexAssignedAtBirth
