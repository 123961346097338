import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import LabelIcon from '@mui/icons-material/Label'
import {
  Box,
  Card,
  CardHeader,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import { useActions } from '../../hooks/useActions'

function HeartConditionsList({ id, heartConditions }) {
  const { removePersonConditions } = useActions()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return heartConditions.map(({ id: conditionId, label, treatments = [], qualifiers = [] }) => {
    const { treatmentLocation = '', patientAddress = '' } =
      treatments.find(({ type }) => type === 'other') || {}
    const { type: ageAtDiagnosis = '' } =
      qualifiers.find(({ label }) => label === 'Age at diagnosis') || {}
    const renderTreatmentList = (treatmentLabel, treatmentField) => {
      if (!treatmentField) return null

      return (
        <Typography key={treatmentLabel} variant="subtitle2">
          {treatmentLabel + ': '} <b>{treatmentField}</b>
        </Typography>
      )
    }

    return (
      <Container key={conditionId}>
        <Card elevation={2} sx={{ borderRadius: 2 }}>
          <CardHeader
            sx={{
              backgroundColor: (theme) => theme.palette.white.main,
              py: 2,
              px: isMobile ? 2 : 3,
            }}
            avatar={<LabelIcon color="primary" />}
            title={label}
            subheader={
              !!ageAtDiagnosis || !!treatmentLocation || !!patientAddress ? (
                <Box display="flex" flexDirection="column" pt={1}>
                  {[
                    renderTreatmentList('Age at diagnosis', ageAtDiagnosis),
                    renderTreatmentList('Hospital', treatmentLocation),
                    renderTreatmentList('Address', patientAddress),
                  ]}
                </Box>
              ) : null
            }
            action={
              <IconButton
                color="error"
                onClick={() => {
                  removePersonConditions({ id, conditionId })
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            }
          />
        </Card>
      </Container>
    )
  })
}

export default HeartConditionsList
