import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { pathToPerson } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const BreastFeeding = () => {
  const { setPersonPropertiesIsPresent, setPersonPropertiesValue } = useActions()
  const id = useSelector((state) => get(state, ['questionnaire', 'probandId']))
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const hasBreastFed =
    useSelector((state) => {
      const properties = get(state, [...pathToPerson(id), 'properties']) || []

      return properties.find(({ type }) => type === 'hasBreastFed')
    }) || {}

  const actionProps = (value) => ({ id, type: 'hasBreastFed', value })

  return sex === 'F' ? (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N']}
      label="Have you breast fed your children?"
      conditionState={hasBreastFed.isPresent}
      onConditionStateChange={(value) => {
        if (value !== hasBreastFed.isPresent) {
          setPersonPropertiesIsPresent(actionProps(value))
        }
      }}
      cleanUpChildQuestions={() => {
        if (hasBreastFed.value) {
          setPersonPropertiesValue(actionProps(null))
        }
      }}
    >
      <TextFieldQuestion
        label="For how long in total?"
        inputProps={{ maxLength: 300 }}
        handleChange={(value) => {
          setPersonPropertiesValue(actionProps(value))
        }}
        value={hasBreastFed.value || ''}
      />
    </ConditionalSection>
  ) : null
}

export default BreastFeeding
