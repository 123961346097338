import React from 'react'
import errors from '../data/errors.json'
import InfoDisplay from '../widgets/InfoDisplay'

function PageNotFound() {
  return (
    <InfoDisplay
      title={"Sorry, we couldn't find that page"}
      description={'The page you are trying to access does not seem to exist'}
      alert={errors.URL_TYPO}
      color="primary"
    />
  )
}

export default PageNotFound
