import InfoIcon from '@mui/icons-material/Info'
import { Alert, Typography } from '@mui/material'
import React from 'react'
import CenteredContainer from '../widgets/CenteredContainer'

function InfoDisplay({ title, description, alert, color }) {
  const severity = {
    primary: 'info',
    error: 'error',
    warning: 'warning,',
    success: 'success',
  }[color]

  return (
    <CenteredContainer>
      <InfoIcon color={color} sx={{ fontSize: 50 }} />
      <Typography variant="h2" color={color} textAlign="center">
        {title}
      </Typography>
      {description && <Typography color={color}>{description}</Typography>}
      {alert && <Alert severity={severity}>{alert}</Alert>}
    </CenteredContainer>
  )
}

export default InfoDisplay
