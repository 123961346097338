import { Add, Remove } from '@mui/icons-material'
import { Box, IconButton, TextField } from '@mui/material'
import React from 'react'

function IntegerDetails({
  value,
  disabled,
  disabledMinus,
  disabledPlus,
  onChange,
  min = 0,
  max = 150,
  label,
}) {
  const updateVal = (event, buttonValue) => {
    const _value = !event ? buttonValue : parseInt(event.target.value)

    if (Number.isNaN(_value)) {
      return onChange('')
    }

    if (_value > max || _value < min) return
    onChange(_value)
  }

  const handleIncrease = () => {
    if (value >= max) {
      return
    }
    if (value === '') {
      return updateVal(null, 1)
    }
    updateVal(null, value + 1)
  }

  const handleDecrease = () => {
    if (value <= min) {
      return
    }
    updateVal(null, value - 1)
  }

  if (value === 0 || !value) {
    disabledMinus = true
  }
  if (value === 150) {
    disabledPlus = true
  }

  return (
    <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
      <IconButton
        onClick={handleDecrease}
        disabled={disabledMinus}
        aria-label="decrease number by 1"
      >
        <Remove />
      </IconButton>
      <TextField
        type="number"
        onChange={updateVal}
        onKeyDown={(e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()}
        value={value}
        InputProps={{ inputProps: { min, max, 'aria-label': label } }}
        disabled={disabled}
        autoComplete="off"
      />
      <IconButton
        onClick={handleIncrease}
        disabled={disabledPlus}
        aria-label="increase number by 1"
      >
        <Add />
      </IconButton>
    </Box>
  )
}

export default IntegerDetails
