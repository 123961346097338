import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import ConditionalSection from '../../widgets/ConditionalSection'

const ElevatedPSA = ({ id }) => {
  const { setPersonElevatedPSA } = useActions()
  const localization = useLocalization()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const elevatedPSADiagnostic =
    useSelector((state) => {
      const diagnosticTests = get(state, [...pathToPerson(id), 'diagnosticTests']) || []

      return diagnosticTests.find(({ type }) => type === 'elevatedPSA')
    }) || {}

  return sex === 'M' ? (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => null}
      label={getLabel(localization, PersonFieldPaths.ELEVATED_PSA_LABEL, 'patient')}
      conditionState={elevatedPSADiagnostic.isPerformed}
      onConditionStateChange={(value) => {
        if (!elevatedPSADiagnostic.type) {
          setPersonElevatedPSA({ id, type: 'type', value: 'elevatedPSA' })
        }
        if (elevatedPSADiagnostic?.isPerformed !== value) {
          setPersonElevatedPSA({ id, type: 'isPerformed', value })
        }
      }}
    />
  ) : null
}

export default ElevatedPSA
