import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { pathToPerson } from '../utils/helpers'
import Chooser from './Chooser'
import ConditionalSection from './ConditionalSection'

const GenderIdentity = ({ id }) => {
  const [isGenderDifferent, setIsGenderDifferent] = useState(null)
  const { removePersonProperty, setPersonGenderIdentity } = useActions()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const genderIdentity =
    useSelector((state) => get(state, [...pathToPerson(id), 'genderIdentity'])) || ''

  useEffect(() => {
    if (genderIdentity) setIsGenderDifferent('Y')
  }, [genderIdentity])

  return (
    <ConditionalSection
      label="Is your gender identity different than this?"
      showWhenCondition="Y"
      orderSchema={['Y', 'N']}
      conditionState={isGenderDifferent}
      onConditionStateChange={setIsGenderDifferent}
      cleanUpChildQuestions={() => {
        removePersonProperty({ id, type: 'genderIdentity' })
      }}
    >
      <Chooser
        label="What is your current identity?"
        selectedValue={genderIdentity}
        onChange={(value) => {
          if (genderIdentity !== value) {
            setPersonGenderIdentity({ id, value })
          }
        }}
        orderSchema={['M', 'F', 'nonBinary', 'O'].filter((_sex) => sex !== _sex)}
        patient="patient"
        path="genderIdentity"
      />
    </ConditionalSection>
  )
}

export default GenderIdentity
