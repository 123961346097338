import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization, useProband } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import {
  PersonFieldPaths as paths,
  PersonProperties as properties,
} from '../../utils/QuestionnaireStateManager'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

export default function RelativeGeneticTesting({ personId, personLabel, lastItemUnchecked }) {
  const { setPersonGeneticTesting, removeRelativeGeneticTesting, setPersonPropertiesIsPresent } =
    useActions()
  const localization = useLocalization()
  const proband = useProband()
  const geneticTesting =
    useSelector((state) => get(state, [...pathToPerson(personId), 'geneticTesting'])) || {}
  const isChecked = !!(geneticTesting.performed === 'Y')

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <TextFieldQuestion
            key="gtp"
            label={getLabel(localization, paths.GENETIC_TESTING_DESCRIPTION, 'patient')}
            value={geneticTesting.description ?? ''}
            handleChange={(value) =>
              setPersonGeneticTesting({
                id: personId,
                type: 'description',
                value,
              })
            }
          />
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    const { properties: probandProperties = [] } = proband
    const hasProbandProperty =
      probandProperties.find(({ type }) => type === properties.HAS_RELATIVE_SEEN_ELSEWHERE)
        ?.isPresent !== 'Y'

    // on checkbox selected
    if (e.target.checked) {
      if (hasProbandProperty) {
        setPersonPropertiesIsPresent({
          id: proband.id,
          type: properties.HAS_RELATIVE_SEEN_ELSEWHERE,
          value: 'Y',
        })
      }
      setPersonGeneticTesting({
        id: personId,
        type: 'performed',
        value: 'Y',
      })

      return
    }
    // on checkbox deselected
    if (lastItemUnchecked()) {
      setPersonPropertiesIsPresent({
        id: proband.id,
        type: properties.HAS_RELATIVE_SEEN_ELSEWHERE,
        value: 'N',
      })
    }
    removeRelativeGeneticTesting({
      id: personId,
      type: 'geneticTesting',
    })
  }
}
