import { Container, Typography, FormLabel, FormControl } from '@mui/material'
import React from 'react'
import { useStore } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useLocalization, useShallowSelector } from '../../redux/reduxHooks'
import { getLabel, getPersonLabel } from '../../utils/helpers'
import {
  PersonFieldPaths as paths,
  PersonProperties as properties,
  PersonSurgeries as surgeries,
  PersonConditions as conditions,
} from '../../utils/QuestionnaireStateManager'
import DiagnosedCancer from './DiagnosedCancer'
import PreviouslySeen from './PreviouslySeen'
import RelativeCardiacDevice from './RelativeCardiacDevice'
import RelativeConditions from './RelativeConditions'
import RelativeGender from './RelativeGender'
import RelativeGeneticTesting from './RelativeGeneticTesting'
import RelativeHeartConditions from './RelativeHeartConditions'
import { Hysterectomy, Oophorectomy } from './RelativeSurgery'

function RelativeLinkingQuestions({
  navId,
  configId,
  propertyPath,
  propertyType,
  personProperty,
  label,
}) {
  const localization = useLocalization()
  const persons = useShallowSelector((state) => state.questionnaire.persons) || {}
  const store = useStore()

  let relatives = Object.values(persons).filter(
    (person) =>
      person.relationshipToProband !== 'proband' &&
      person.relationshipToProband !== 'probandsPartner',
  )

  const isFemaleOnlyProperty =
    personProperty === surgeries.HYSTERECTOMY || personProperty === surgeries.OOPHORECTOMY

  if (isFemaleOnlyProperty) {
    relatives = relatives.filter(({ sex }) => sex === 'F')
  }

  const content = []
  relatives.forEach((person) => {
    const { name, relationship } = getPersonLabel(person)
    const props = {
      key: `relative-linking-${person.id}`,
      personId: person.id,
      personLabel: (
        <>
          {name}
          <em>{name ? ` (${relationship})` : relationship}</em>
        </>
      ),
      lastItemUnchecked,
    }
    switch (navId) {
      case properties.HAS_BEEN_SEEN_BY_TEAM:
        content.push(<PreviouslySeen {...props} />)
        break
      case properties.HAS_CANCER:
        content.push(<DiagnosedCancer {...props} />)
        break
      case surgeries.HYSTERECTOMY:
        content.push(<Hysterectomy {...props} />)
        break
      case surgeries.OOPHORECTOMY:
        content.push(<Oophorectomy {...props} />)
        break
      // conditions
      case conditions.PANCREATITIS:
      case conditions.TUMOUR:
      case conditions.PITUITARY:
      case conditions.MOLE_NEVI:
      case conditions.BLOOD_CLOT:
      case conditions.POLYPS_REMOVED:
      case 'epilepsy':
      case 'hearingLoss':
        content.push(<RelativeConditions conditionId={navId} configId={configId} {...props} />)
        break
      case 'geneticTesting':
        content.push(<RelativeGeneticTesting {...props} />)
        break
      case 'genderIdentity':
        content.push(<RelativeGender {...props} />)
        break
      case 'heartConditions':
        content.push(<RelativeHeartConditions {...props} />)
        break
      case 'cardiacDevice':
        content.push(<RelativeCardiacDevice {...props} />)
        break
      default:
        break
    }
  })

  RemoveLoadingHook()

  return (
    <Container>
      <Typography>{getQuestionLabel()}</Typography>
      {relatives.length ? (
        <FormControl>
          <FormLabel>Please select all who apply:</FormLabel>
          {content}
        </FormControl>
      ) : (
        <p>
          There are no&nbsp;
          <b>{isFemaleOnlyProperty && 'female '}</b>
          relatives entered. Please add family members in previous steps
        </p>
      )}
    </Container>
  )

  /**
   * Returns the question title for the page.
   * @returns {String} The formatted question title
   **/
  function getQuestionLabel() {
    return (
      label ||
      getLabel(localization, propertyPath, propertyType === paths.CONDITIONS ? 'person' : 'patient')
    )
  }
  /**
   * List of properties and ids if the relatives are selected
   * @returns {Array<Object>}
   **/
  function lastItemUnchecked() {
    const persons = store.getState().questionnaire.persons

    const relativesChecked = relatives.reduce((accumulator, relative) => {
      const personProperties = persons[relative.id][propertyType]
      if (!personProperties) return accumulator

      if (!Array.isArray(personProperties)) {
        const numberOfProperties = Object.keys(personProperties).length

        !!numberOfProperties && accumulator.push({ ...properties, id: relative.id })
      } else {
        const property = personProperties.find(({ id, type }) => {
          return id ? id === personProperty : type === personProperty
        })

        !!property && accumulator.push({ ...property, id: relative.id })
      }

      return accumulator.flat()
    }, [])

    return relativesChecked.length === 1
  }
}

export { RelativeLinkingQuestions }
