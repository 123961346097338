import { isEmpty, assign, get, find } from 'lodash'
import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import Navigation from '../containers/Navigation.jsx'
import SessionTimeout from '../containers/SessionTimeout'
import { useActions } from '../hooks/useActions.jsx'
import questionnaireMap from '../questionnaireMap/index.js'
import { useShallowSelector } from '../redux/reduxHooks.js'
import { Children, Siblings } from '../steps/index.js'
import Person from '../steps/Person'
import Context from '../utils/context/Context'
import Spinner from '../widgets/Spinner.jsx'

const Questionnaire = () => {
  const {
    authState: { isAuthenticated },
    setAuthState,
    dateOfBirth,
  } = useContext(Context)
  const probandId = useSelector((state) => state.questionnaire.probandId) || ''
  const probandDOB = useSelector((state) =>
    get(state, ['questionnaire', 'persons', probandId, 'dateOfBirth']),
  )
  const persons = useShallowSelector((state) => state.questionnaire.persons) || {}
  const configuration = useSelector((state) => state.configuration?.sectionConfig) || {}
  const isLocalizationInstalled = useSelector((state) => !isEmpty(state.localization))
  const {
    setInitialState,
    setLocalization,
    setConfiguration,
    sendMetricsData,
    setPersonDateOfBirth,
    sendStateData,
    resolveParent,
  } = useActions()

  useEffect(() => {
    if (!isAuthenticated) {
      setInitialState({
        setAuthState,
        setLocalization,
        setConfiguration,
      })
      sendMetricsData()
    }
  }, [])

  useEffect(() => {
    if (probandId && !probandDOB) {
      for (const dateType in dateOfBirth) {
        setPersonDateOfBirth({
          id: probandId,
          dateType,
          value: dateOfBirth[dateType],
        })
      }
      sendStateData()
    }
  }, [probandId])

  if (!(isAuthenticated && isLocalizationInstalled && Object.keys(configuration).length)) {
    return <Spinner message="Loading the Application..." />
  }

  return (
    <SessionTimeout>
      <Navigation>
        {questionnaireMap({
          configuration,
          renderProbandFamilySteps,
          renderMotherSteps,
          renderFatherSteps,
          renderFolder,
        })}
      </Navigation>
    </SessionTimeout>
  )

  /**
   * Returns a formatted array of components that Navigation will render as a sidebar Folder
   * @param {String} id Folder component key
   * @param {String} label The label text that will show in the sidebar
   * @param {Array<ReactDOM>} components List of steps that will be in the folder
   * @returns {Array}
   **/
  function renderFolder(id, label, components = null) {
    if (components) {
      return [[{ id, label, type: 'folder' }, ...components]]
    } else {
      return []
    }
  }

  function motherResolver() {
    return (shouldCreate) =>
      resolvePerson('probandsMother', () =>
        resolveParent({ id: probandId, sex: 'F', shouldCreate, title: 'probandsMother' }),
      )
  }

  function fatherResolver() {
    return (shouldCreate) =>
      resolvePerson('probandsFather', () =>
        resolveParent({ id: probandId, sex: 'M', shouldCreate, title: 'probandsFather' }),
      )
  }

  function renderGrandparent({
    navTitle,
    idLabel,
    parent,
    parentSex,
    grandparent,
    grandparentSex,
  }) {
    return renderPersonStep(navTitle, idLabel, grandparentSex, (shouldCreate) => {
      const parentId = resolvePerson(parent, () =>
        resolveParent({ id: probandId, sex: parentSex, shouldCreate, title: parent }),
      )

      // prevents a relationship created with no target person (missing mother/father)
      return parentId
        ? resolvePerson(grandparent, () =>
            resolveParent({ id: parentId, sex: grandparentSex, shouldCreate, title: grandparent }),
          )
        : ''
    })
  }

  function renderProbandFamilySteps() {
    return [
      [
        {
          id: 'patientsFamilyStep',
          label: 'Your Family',
          type: 'folder',
        },
        renderPatientsChildrenStep(),
        renderPersonSiblingCountsStep('Siblings', 'proband'),
        renderPersonStep('Mother', 'mother', 'F', motherResolver()),
        renderPersonStep('Father', 'father', 'M', fatherResolver()),
      ],
    ]
  }

  function renderMotherSteps() {
    const mothersFamilyStep = [
      {
        id: 'mothersFamilyStep',
        label: "Your Mother's Family",
        type: 'folder',
      },
      renderPersonSiblingCountsStep("Your Mother's Siblings", 'mother', motherResolver()),
      renderGrandparent({
        navTitle: "Your Mother's Mother",
        idLabel: 'mat-gmother',
        parent: 'probandsMother',
        parentSex: 'F',
        grandparent: 'probandsMaternalGrandmother',
        grandparentSex: 'F',
      }),
      renderGrandparent({
        navTitle: "Your Mother's Father",
        idLabel: 'mat-gfather',
        parent: 'probandsMother',
        parentSex: 'F',
        grandparent: 'probandsMaternalGrandfather',
        grandparentSex: 'M',
      }),
    ]

    const isMotherAdopted = persons[motherResolver()]?.adopted ?? ''

    return isMotherAdopted !== 'Y' ? [mothersFamilyStep] : null
  }

  function renderFatherSteps() {
    const fathersFamilyStep = [
      {
        id: 'fathersFamilyStep',
        label: "Your Father's Family",
        type: 'folder',
      },
      renderPersonSiblingCountsStep("Your Father's Siblings", 'father', fatherResolver()),
      renderGrandparent({
        navTitle: "Your Father's Mother",
        idLabel: 'pat-gmother',
        parent: 'probandsFather',
        parentSex: 'M',
        grandparent: 'probandsPaternalGrandmother',
        grandparentSex: 'F',
      }),
      renderGrandparent({
        navTitle: "Your Father's Father",
        idLabel: 'pat-gfather',
        parent: 'probandsFather',
        parentSex: 'M',
        grandparent: 'probandsPaternalGrandfather',
        grandparentSex: 'M',
      }),
    ]
    const isFatherAdopted = persons[fatherResolver()]?.adopted ?? ''

    return isFatherAdopted !== 'Y' ? [fathersFamilyStep] : null
  }

  function renderFamilyStep(
    isSiblingCountsStep,
    title,
    idLabel,
    customRenderProps,
    iconClassName,
    resolvePersonId = null,
    removePerson = false,
  ) {
    let id

    if (isSiblingCountsStep) {
      id = 'person-' + idLabel + '-siblings'
    } else {
      id = 'person-' + idLabel
    }

    const renderProps = {
      key: id,
      navId: id,
      navTitle: title,
      icon: iconClassName,
      targetPerson: idLabel,
      removePerson,
    }

    if (resolvePersonId) {
      renderProps.resolvePersonId = resolvePersonId
    }

    if (customRenderProps && typeof customRenderProps === 'object') {
      assign(renderProps, customRenderProps)
    }

    if (isSiblingCountsStep) {
      return <Siblings {...renderProps} />
    } else {
      return <Person {...renderProps} />
    }
  }

  // resolvePersonId is not required
  function renderPersonStep(title, idLabel, sex, resolvePersonId = undefined) {
    let renderProps

    if (sex) {
      renderProps = renderProps || {}
      renderProps.sex = sex
    }

    return renderFamilyStep(false, title, idLabel, renderProps, 'person', resolvePersonId)
  }

  // resolvePersonId is not required
  function renderPersonSiblingCountsStep(
    title,
    idLabel,
    resolvePersonId = probandId,
    relationshipPrefix = undefined,
  ) {
    return renderFamilyStep(
      true,
      title,
      idLabel,
      null,
      'people',
      resolvePersonId,
      relationshipPrefix,
    )
  }

  function renderPatientsChildrenStep() {
    const key = 'probands-children'

    return <Children key={key} navId={key} icon="people" navTitle="Children" />
  }

  function resolvePerson(relationshipToProband, createPerson) {
    if (!find(persons, { relationshipToProband })) {
      createPerson()
    }

    return find(persons, { relationshipToProband })?.id
  }
}

export default Questionnaire
