/* eslint-disable prefer-regex-literals */

export const getNewMetrics = ({
  questionnaire,
  metrics,
  newDestinationValue = undefined,
  eventType = undefined,
}) => {
  const { destinationPage } = metrics
  const regexp = new RegExp(/hasRelative.*/)
  const persons = {}
  const hasRelativeValues = []

  let _personId = ''
  let _relationshipToProband = ''
  let tempKeyName = ''

  // Persons
  const getQuestionsAnswered = (
    data,
    nestedKeyName,
    nestedLevel,
    personId = undefined,
    relationshipToProband = undefined,
  ) => {
    if (personId) {
      persons[personId] = { relationshipToProband, list: [] }
      _personId = personId
      _relationshipToProband = relationshipToProband
    }

    const addKey = (nestedKeyName) => {
      if (persons[_personId].list.includes(nestedKeyName)) return
      persons[_personId].list.push(nestedKeyName)
    }

    if (typeof data === 'string') {
      const parsedNestedKeyName = nestedKeyName.split('.')

      // `if` statements here are to block or process the unnecessary or detail data to be saved
      // Also the default data not from the user is not going to be saved,
      // for instance { sex: 'U' } of the proband.
      if (nestedKeyName === 'id' || nestedKeyName === 'relationshipToProband') return
      if (
        nestedKeyName === 'diagnosticTests.isPerformed' ||
        nestedKeyName === 'treatments.isPerformed'
      )
        return
      if (_relationshipToProband === 'proband' && nestedKeyName === 'sex' && data === 'U') return
      if (_relationshipToProband !== 'proband' && nestedKeyName === 'sex') return

      if (
        nestedKeyName === 'properties.type' ||
        nestedKeyName === 'diagnosticTests.type' ||
        nestedKeyName === 'surgeries.type' ||
        nestedKeyName === 'treatments.type'
      ) {
        tempKeyName = parsedNestedKeyName[0] + '.' + data
        nestedKeyName = tempKeyName
      }

      if (
        nestedKeyName === 'properties.value' ||
        nestedKeyName === 'diagnosticTests.result' ||
        nestedKeyName === 'surgeries.lateralityType' ||
        nestedKeyName === 'surgeries.reason'
      ) {
        nestedKeyName = tempKeyName + '.' + parsedNestedKeyName[1]
        tempKeyName = ''
      }

      if (
        parsedNestedKeyName[0] === 'properties' &&
        (nestedKeyName === 'properties.value' ||
          nestedKeyName === 'properties.isPerformed' ||
          nestedKeyName === 'properties.twin.value' ||
          nestedKeyName === 'properties.sharedParent.value')
      )
        return

      if (nestedKeyName === 'cancers.id' || nestedKeyName === 'conditions.id') {
        nestedKeyName = parsedNestedKeyName[0] + '.' + data + '.' + 'id'
      }

      if (
        parsedNestedKeyName[0] === 'cancers' &&
        (parsedNestedKeyName[parsedNestedKeyName.length - 1] !== 'id' ||
          nestedKeyName.includes('cancers.qualifiers'))
      )
        return

      if (
        parsedNestedKeyName[0] === 'conditions' &&
        parsedNestedKeyName[parsedNestedKeyName.length - 1] !== 'id'
      )
        return

      // These attributes in proband are not deleted in our state
      // when the user deletes in relative related pages.
      // It should be handled outside this function.
      if (
        nestedKeyName === 'properties.hasRelativeHasCancer' ||
        nestedKeyName === 'properties.hasRelativeSeenElsewhere' ||
        nestedKeyName === 'properties.hasRelativeHaveHysterectomy' ||
        nestedKeyName === 'properties.hasRelativeRemovedOvaries' ||
        nestedKeyName === 'properties.hasRelativeSeenByTeam'
      ) {
        hasRelativeValues.push({ key: nestedKeyName })
      }

      if (nestedKeyName === 'properties.isPresent' && regexp.test(tempKeyName)) {
        hasRelativeValues[hasRelativeValues.length - 1] = {
          ...hasRelativeValues[hasRelativeValues.length - 1],
          isPresent: data,
        }
      }

      if (nestedKeyName === 'properties.isPresent') return

      addKey(nestedKeyName)

      return
    }

    if (typeof data === 'number') {
      const parsedNestedKeyName = nestedKeyName.split('.')

      if (nestedKeyName.includes('cancers.')) return

      if (nestedKeyName === 'diagnosticTests.ageAtTestPerformed') {
        nestedKeyName = tempKeyName + '.' + parsedNestedKeyName[1]
        tempKeyName = ''
      }

      if (nestedKeyName === 'surgeries.age') {
        nestedKeyName = tempKeyName + '.' + parsedNestedKeyName[1]
      }

      addKey(nestedKeyName)

      return
    }

    if (typeof data === 'boolean') {
      addKey(nestedKeyName)

      return
    }

    if (typeof data === 'object') {
      nestedLevel++

      if (Array.isArray(data)) {
        if (typeof data[0] === 'object') {
          nestedLevel--
        }

        if (data.length) {
          for (const value of data) {
            getQuestionsAnswered(value, nestedKeyName, nestedLevel)
          }
        }

        return
      }

      for (const stateKey in data) {
        if (data[stateKey] !== undefined) {
          const nextType = data[stateKey]

          const makeKeyStrings = () => {
            if (nestedKeyName) {
              let currentKeyStrings = nestedKeyName.split('.')

              if (currentKeyStrings.length >= nestedLevel) {
                currentKeyStrings = currentKeyStrings.slice(0, nestedLevel - 1)
                nestedKeyName = currentKeyStrings.join('.')
              }
              nestedKeyName += '.' + stateKey
            }
          }

          if (typeof nextType === 'string') {
            makeKeyStrings()
          }

          if (typeof nextType === 'number') {
            makeKeyStrings()
          }

          if (typeof nextType === 'boolean') {
            makeKeyStrings()
          }

          if (typeof nextType === 'object' && Array.isArray(nextType) && nextType.length) {
            makeKeyStrings()
          }

          if (
            typeof nextType === 'object' &&
            !Array.isArray(nextType) &&
            Object.keys(nextType).length
          ) {
            makeKeyStrings()
          }

          getQuestionsAnswered(data[stateKey], nestedKeyName || stateKey, nestedLevel)
        }
      }
    }
  }

  for (const personId in questionnaire.persons) {
    getQuestionsAnswered(
      questionnaire.persons[personId],
      null,
      0,
      questionnaire.persons[personId].id,
      questionnaire.persons[personId].relationshipToProband,
    )
  }

  // Relationship
  const answeredRelationshipProperties = {}

  let _value = ''

  for (const { id, properties, type } of questionnaire.relationships) {
    const list = []

    properties.forEach((property) => {
      Object.entries(property).forEach(([key, value]) => {
        if (key === 'type' || key === 'value') {
          if (key === 'type') {
            _value = value
          }

          list.push(`property.${_value}.${key === 'value' ? 'description' : key}`)
        }
      })
    })

    answeredRelationshipProperties[id] = { type, list }
  }

  // Manipulate the `hasRelative*` values in terms of the `isPresent values`
  const probandList = [...persons[questionnaire.probandId].list].filter((key) => {
    return !hasRelativeValues.some(({ key: _key, isPresent }) => key === _key && isPresent === 'N')
  })

  // For initialQuestionnaire
  let newMetricData = {
    answeredQuestions: {
      persons: {
        ...persons,
        [questionnaire.probandId]: {
          ...persons[questionnaire.probandId],
          list: probandList,
        },
      },
      relationships: answeredRelationshipProperties,
      notes: questionnaire.notes ? 'Y' : 'N',
    },
    destinationPage,
  }

  // For next, previous, step and etc. buttons
  if (eventType && newDestinationValue) {
    newMetricData = {
      ...newMetricData,
      eventType,
      sourcePage: destinationPage,
      destinationPage: newDestinationValue,
    }
  }

  return newMetricData
}
