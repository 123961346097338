import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Card, CardContent, CardHeader, Collapse } from '@mui/material'
import React, { useState } from 'react'
import { theme } from '../theme'

const CollapsibleError = ({ title, children, ...props }) => {
  const [expanded, setExpanded] = useState(false)
  const p = theme.palette

  return (
    <Card sx={{ borderRadius: 2 }} {...props}>
      <CardHeader
        sx={{
          backgroundColor: p.error.bg,
          cursor: 'pointer',
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          '& .MuiCardHeader-title': {
            color: p.error.main,
            fontSize: '1.71rem',
          },
        }}
        title={title}
        action={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={() => setExpanded(!expanded)}
      />
      <Collapse in={expanded}>
        <CardContent sx={{ maxHeight: '250px', overflowY: 'scroll' }}>{children}</CardContent>
      </Collapse>
    </Card>
  )
}

export default CollapsibleError
