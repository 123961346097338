import { PersonProperties, PersonFieldPaths, PersonSurgeries } from './QuestionnaireStateManager'

export const cancerRelativeQuestions = [
  {
    id: PersonProperties.HAS_BEEN_SEEN_BY_TEAM,
    configId: 'previous-seen',
    title: 'Previously seen',
    propertyPath: PersonFieldPaths.HAS_RELATIVE_SEEN_BY_TEAM,
    propertyType: PersonFieldPaths.PROPERTIES,
    personProperty: PersonProperties.HAS_BEEN_SEEN_BY_TEAM,
    otherQuestions: true,
  },
  {
    id: PersonProperties.HAS_CANCER,
    configId: 'diagnosed-with-cancer',
    title: 'Diagnosed with cancer',
    propertyPath: PersonFieldPaths.HAS_RELATIVE_HAS_CANCER,
    propertyType: PersonFieldPaths.PROPERTIES,
    personProperty: PersonProperties.HAS_CANCER,
    otherQuestions: true,
  },
  {
    id: PersonSurgeries.HYSTERECTOMY,
    configId: 'hysterectomy',
    title: 'Hysterectomy',
    propertyPath: PersonFieldPaths.HAS_RELATIVE_HAVE_HYSTERECTOMY,
    propertyType: PersonFieldPaths.SURGERIES,
    personProperty: PersonSurgeries.HYSTERECTOMY,
    otherQuestions: true,
  },
  {
    id: PersonSurgeries.OOPHORECTOMY,
    configId: 'oophorectomy',
    title: 'Oophorectomy',
    propertyPath: PersonFieldPaths.HAS_RELATIVE_REMOVED_OVARIES,
    propertyType: PersonFieldPaths.SURGERIES,
    personProperty: PersonSurgeries.OOPHORECTOMY,
    otherQuestions: true,
  },
]

export default cancerRelativeQuestions
