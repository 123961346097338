import { FormControl } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import ConditionalSection from '../../widgets/ConditionalSection'
import { SubQuestionLabel } from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const PolypsResult = ({ value, onChange, bowelPolyps }) => {
  const resultLabel = 'How many?'

  return bowelPolyps?.isPresent === 'Y' ? (
    <FormControl key="cpp">
      <SubQuestionLabel>{resultLabel}</SubQuestionLabel>
      <IntegerDetails onChange={onChange} value={value} label={resultLabel} />
    </FormControl>
  ) : null
}
const ColonoscopyAge = ({ label, onChange, value }) => {
  return (
    <FormControl key="ca" label={label}>
      <SubQuestionLabel>{label}</SubQuestionLabel>
      <IntegerDetails label={label} onChange={onChange} value={value} />
    </FormControl>
  )
}
const Colonoscopy = ({ id, patient, config }) => {
  const {
    setPersonColonoscopyStatus,
    removePersonColonoscopyStatus,
    setPersonBowelPolyps,
    setPersonBowelPolypsCount,
  } = useActions()
  const localization = useLocalization()
  const colonoscopyDiagnostic =
    useSelector((state) => {
      const diagnosticTests = get(state, [...pathToPerson(id), 'diagnosticTests']) || []

      return diagnosticTests.find(({ type }) => type === 'colonoscopy')
    }) || {}
  const bowelPolyps = colonoscopyDiagnostic.diagnosticTestQualifiers?.find(
    ({ diagnosticTestQualifierType }) => diagnosticTestQualifierType === 'bowelPolyps',
  )

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      label={getLabel(localization, paths.COLONOSCOPY_LABEL, patient)}
      conditionState={colonoscopyDiagnostic.isPerformed}
      onConditionStateChange={(value) => {
        if (!colonoscopyDiagnostic.type) {
          setPersonColonoscopyStatus({ id, type: 'type', value: 'colonoscopy' })
        }
        if (colonoscopyDiagnostic.isPerformed !== value) {
          setPersonColonoscopyStatus({ id, type: 'isPerformed', value })
        }
      }}
      cleanUpChildQuestions={() => {
        removePersonColonoscopyStatus({ id, type: 'ageAtTestPerformed' })
        removePersonColonoscopyStatus({ id, type: 'lastPerformedDate' })
        removePersonColonoscopyStatus({ id, type: 'performedAtLocation' })
        removePersonColonoscopyStatus({ id, type: 'diagnosticTestQualifiers' })
      }}
    >
      {renderContent({
        renderMap: {
          colonoscopyAge: (
            <ColonoscopyAge
              key="ca"
              label={getLabel(localization, paths.COLONOSCOPY_AGE_LABEL, patient)}
              onChange={(value) =>
                setPersonColonoscopyStatus({ id, type: 'ageAtTestPerformed', value })
              }
              value={colonoscopyDiagnostic.ageAtTestPerformed ?? ''}
            />
          ),
          colonoscopyLastDate: (
            <TextFieldQuestion
              key="cd"
              label="Date last performed"
              value={colonoscopyDiagnostic.lastPerformedDate ?? ''}
              handleChange={(value) =>
                setPersonColonoscopyStatus({ id, type: 'lastPerformedDate', value })
              }
              subQuestion
            />
          ),
          colonoscopyLocation: (
            <TextFieldQuestion
              key="cl"
              label="Hospital and consultant"
              value={colonoscopyDiagnostic.performedAtLocation ?? ''}
              handleChange={(value) =>
                setPersonColonoscopyStatus({ id, type: 'performedAtLocation', value })
              }
              subQuestion
            />
          ),
          colonoscopyPolyps: (
            <Chooser
              key="cp"
              label="Have you had any bowel polyps?"
              onChange={(value) => setPersonBowelPolyps({ id, value })}
              selectedValue={bowelPolyps?.isPresent}
              orderSchema={['Y', 'N', 'U']}
              path="yesNoUnknown"
              subQuestion
            />
          ),
          colonoscopyPolypsResult: (
            <PolypsResult
              key="cpr"
              bowelPolyps={bowelPolyps}
              value={bowelPolyps?.value ?? ''}
              onChange={(value) => setPersonBowelPolypsCount({ id, value })}
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default Colonoscopy
