import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import { renderContent } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const propertyType = {
  CARDIAC_ARREST: 'hasRelativeHadCardiacArrest',
  ACCIDENTAL_DEATH: 'hasRelativeHadAccidentalDeath',
  INFANT_DEATH: 'hasRelativeHadInfantDeaths',
}

const RelativeDeathDetails = ({ config }) => {
  const { properties = [], probandId } = useSelector(
    ({ questionnaire: { persons, probandId } }) => ({
      properties: persons[probandId].properties,
      probandId,
    }),
  )
  const { setPersonPropertiesValue, setPersonPropertiesIsPresent } = useActions()
  const cardiacArrest = findProperty(propertyType.CARDIAC_ARREST) || {}
  const accidentalDeath = findProperty(propertyType.ACCIDENTAL_DEATH) || {}
  const infantDeath = findProperty(propertyType.INFANT_DEATH) || {}
  const conditionalSelectionProps = {
    orderSchema: ['Y', 'N', 'U'],
    path: 'yesNoUnknown',
    showWhenCondition: 'Y',
  }

  RemoveLoadingHook()

  return (
    <Container>
      {renderContent({
        renderMap: {
          relativeCardiacArrest: (
            <ConditionalSection
              key="rhc"
              label="Have any of your relatives died as a result of a sudden cardiac arrest?"
              conditionState={cardiacArrest.isPresent || ''}
              onConditionStateChange={(value) => {
                setPersonPropertiesIsPresent({
                  id: probandId,
                  type: propertyType.CARDIAC_ARREST,
                  value,
                })
              }}
              cleanUpChildQuestions={() =>
                setPersonPropertiesValue({
                  id: probandId,
                  type: propertyType.CARDIAC_ARREST,
                  value: '',
                })
              }
              {...conditionalSelectionProps}
            >
              <TextFieldQuestion
                label="Please provide some details around the circumstances of their death,
                including their age:"
                value={cardiacArrest.value || ''}
                handleChange={(value) => {
                  setPersonPropertiesValue({
                    id: probandId,
                    type: propertyType.CARDIAC_ARREST,
                    value,
                  })
                }}
              />
            </ConditionalSection>
          ),
          relativeAccidentalDeath: (
            <ConditionalSection
              key="rad"
              label="Have any of your relatives not previously mentioned died in an unexplained
              drowning, motor vehicle accident or any other unexplained reason?"
              conditionState={accidentalDeath.isPresent || ''}
              onConditionStateChange={(value) => {
                setPersonPropertiesIsPresent({
                  id: probandId,
                  type: propertyType.ACCIDENTAL_DEATH,
                  value,
                })
              }}
              cleanUpChildQuestions={() =>
                setPersonPropertiesValue({
                  id: probandId,
                  type: propertyType.ACCIDENTAL_DEATH,
                  value: '',
                })
              }
              {...conditionalSelectionProps}
            >
              <TextFieldQuestion
                label="Please provide details around their death, including their age:"
                value={accidentalDeath.value || ''}
                handleChange={(value) => {
                  setPersonPropertiesValue({
                    id: probandId,
                    type: propertyType.ACCIDENTAL_DEATH,
                    value,
                  })
                }}
              />
            </ConditionalSection>
          ),
          infantDeaths: (
            <ConditionalSection
              key="id"
              label="Have there been any infant deaths in your family or cases of
              sudden infant death syndrome (SIDS)?"
              conditionState={infantDeath.isPresent || ''}
              onConditionStateChange={(value) => {
                setPersonPropertiesIsPresent({
                  id: probandId,
                  type: propertyType.INFANT_DEATH,
                  value,
                })
              }}
              cleanUpChildQuestions={() =>
                setPersonPropertiesValue({
                  id: probandId,
                  type: propertyType.INFANT_DEATH,
                  value: '',
                })
              }
              {...conditionalSelectionProps}
            >
              <TextFieldQuestion
                label="Please provide details around the circumstances of their death,
                including their age of death"
                value={infantDeath.value || ''}
                handleChange={(value) => {
                  setPersonPropertiesValue({
                    id: probandId,
                    type: propertyType.INFANT_DEATH,
                    value,
                  })
                }}
              />
            </ConditionalSection>
          ),
        },
        config,
      })}
    </Container>
  )

  function findProperty(propertyType) {
    return properties.find(({ type }) => type === propertyType)
  }
}

export { RelativeDeathDetails }
