import { getPerson } from '../../../slices/questionnaireSlice/utils/helpers'

export default {
  /**
   * Set a cancer object in person.cancers list: [{}]
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, cancerId: string, label: string }}
   * )}
   */
  setPersonCancers: (state, action) => {
    const person = getPerson(state, action)
    const { value } = action.payload

    if (!value.length) {
      delete person.cancers

      return
    }

    const _value = value.map((cancer) => {
      cancer.qualifiers.forEach((qualifier) => {
        if (qualifier.numericAgeAtDiagnosis === '') {
          delete qualifier.numericAgeAtDiagnosis
        }
      })

      return cancer
    })

    person.cancers = _value
  },
}
