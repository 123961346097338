import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useLocalization } from '../../redux/reduxHooks'
import { getLabel, pathToPerson } from '../../utils/helpers'
import { PersonFieldPaths, PersonConditions } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'

const Endometriosis = ({ id, patient }) => {
  const { setPersonConditions, setPersonConditionIsPresent } = useActions()
  const localization = useLocalization()
  const sex = useSelector((state) => get(state, [...pathToPerson(id), 'sex'])) || ''
  const { isPresent } =
    useSelector((state) => {
      const conditions = get(state, [...pathToPerson(id), 'conditions']) || []

      return conditions.find(({ id }) => id === PersonConditions.ENDOMETRIOSIS)
    }) || {}

  if (sex !== 'F') return null

  return (
    <Chooser
      label={getLabel(localization, PersonFieldPaths.ENDOMETRIOSIS_DIAGNOSED, patient)}
      orderSchema={['Y', 'N', 'U']}
      path="yesNoUnknown"
      selectedValue={isPresent}
      onChange={(value) => {
        if (value && !isPresent) {
          setPersonConditions({ id, conditionId: PersonConditions.ENDOMETRIOSIS })
        }
        setPersonConditionIsPresent({ id, conditionId: PersonConditions.ENDOMETRIOSIS, value })
      }}
    />
  )
}

export default Endometriosis
