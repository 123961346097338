import AddIcon from '@mui/icons-material/Add'
import { Alert, Box, Button, FormControl, FormLabel } from '@mui/material'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import { useFamilyHelperHook } from '../../hooks/useFamilyHelperHook'
import { pathToPerson } from '../../utils/helpers'
import MultiPersonCards from '../../widgets/MultiPersonCards'
import SiblingQuestions from './SiblingQuestions'

function Siblings({ targetPerson, resolvePersonId }) {
  const id = typeof resolvePersonId === 'function' ? resolvePersonId(true) : resolvePersonId
  const familyPath = {
    proband: 'your-family',
    mother: 'mothers-family',
    father: 'fathers-family',
  }[targetPerson]
  const { setSiblings } = useActions()
  const [siblingPersonIds, setSiblingPersonIds] = useState([])
  const config = useSelector((state) =>
    get(state, [
      'configuration',
      'sectionConfig',
      familyPath,
      'subSectionConfig',
      'siblings',
      'questions',
    ]),
  )
  const { persons, getSiblingIds } = useFamilyHelperHook({ id })
  const probandId = useSelector((state) => get(state, ['questionnaire', 'probandId']))
  const adopted = useSelector((state) => get(state, [...pathToPerson(id), 'adopted']))
  const isProband = targetPerson === 'proband'

  RemoveLoadingHook()

  useEffect(() => {
    setSiblingPersonIds([...getSiblingIds()])

    return () => {
      setSiblingPersonIds([])
    }
  }, [persons, id])

  const renderSiblingEntry = (personId) => {
    return (
      <SiblingQuestions
        personId={personId}
        isHalfSibling={isHalfSibling(personId)}
        resolvePersonId={id}
        isProband={isProband}
        probandId={probandId}
        config={config}
      />
    )
  }

  return isProband || adopted !== 'Y' ? (
    <FormControl>
      <FormLabel>
        {isProband ? 'Please add your siblings' : "Please add this person's siblings"}
      </FormLabel>
      <MultiPersonCards
        personIds={siblingPersonIds}
        entryRenderer={renderSiblingEntry}
        sx={{ pt: 2 }}
      />
      <Box pt={2} display="flex" gap={2} flexWrap="wrap">
        {[
          { isHalf: false, label: 'Add Sibling' },
          { isHalf: true, label: 'Add Half-Sibling' },
        ].map(({ isHalf, label }, index) => (
          <Button
            key={label}
            startIcon={<AddIcon />}
            onClick={(e) => buttonHandler(isHalf)}
            sx={{ width: 'fit-content' }}
            variant="contained"
          >
            {label}
          </Button>
        ))}
      </Box>
    </FormControl>
  ) : (
    <Alert severity="info">
      As this person is adopted, you do not need to provide information about their siblings.
    </Alert>
  )
  /**
   * Adds a new sibling when button is clicked
   * @param { Boolean } isHalf
   */
  function buttonHandler(isHalf) {
    const targetPersonArray = ['proband']
    if (['mother', 'father'].includes(targetPerson)) {
      targetPersonArray.push(targetPerson)
    }

    setSiblings({
      designatedPersonId: id,
      numberSharedParents: isHalf ? 'half' : 'full',
      targetPersonArray,
    })
  }
  /**
   * Returns if a person is a half sibling
   * @param {String} personId
   * @returns {Boolean}
   */
  function isHalfSibling(personId) {
    const { relationshipToProband = '' } = persons[personId] || {}

    return relationshipToProband.includes('Half')
  }
}

export { Siblings }
