import { Alert, FormControl, FormLabel } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { getLabel, renderContent } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import IntegerDetails from '../widgets/IntegerDetails'
import Chooser from './Chooser'
import TextFieldQuestion from './TextFieldQuestion.jsx'

const { CAUSE_OF_DEATH, AGE_OF_DEATH, POST_MORTEM } = PersonFieldPaths

const LifeStatusDetails = ({ lifeStatusPath, handleChange, label }) => {
  const causeOfDeath = useSelector((state) => get(state, lifeStatusPath)?.causeOfDeath) || ''

  return (
    <FormControl key="lifeStatusDetails">
      <FormLabel>{label}</FormLabel>
      <TextFieldQuestion
        value={causeOfDeath}
        handleChange={(value) => handleChange({ value, type: 'causeOfDeath' })}
      />
    </FormControl>
  )
}

const LifeStatusAge = ({ lifeStatusPath, handleChange, label }) => {
  const ageOfDeath = useSelector((state) => get(state, lifeStatusPath)?.ageOfDeath) || ''

  return (
    <FormControl key="lifeStatusAge">
      <FormLabel>{label}</FormLabel>
      <IntegerDetails
        value={ageOfDeath}
        onChange={(value) => handleChange({ value, type: 'ageOfDeath' })}
      />
    </FormControl>
  )
}

const PostMortemReport = ({ lifeStatusPath, handleChange, localization }) => {
  const postMortemPerformed =
    useSelector((state) => get(state, lifeStatusPath)?.postMortemPerformed) || ''

  return (
    <>
      <Chooser
        key="postMortemReport"
        label={getLabel(localization, POST_MORTEM)}
        selectedValue={postMortemPerformed}
        onChange={(value) => {
          if (value !== postMortemPerformed) {
            handleChange({ type: 'postMortemPerformed', value })
          }
        }}
        orderSchema={['Y', 'N', 'U']}
        path="yesNoUnknown"
      />
      <Alert key="postMortemReportInfo" severity="info">
        {getLabel(localization, POST_MORTEM, '', 'subLabel')}
      </Alert>
    </>
  )
}

const DeathDetails = ({ personId, localization, config }) => {
  const { setPersonLifeStatus } = useActions()
  const lifeStatusPath = ['questionnaire', 'persons', personId, 'lifeStatus']
  const handleChange = ({ value, type }) => setPersonLifeStatus({ id: personId, type, value })

  return renderContent({
    renderMap: {
      lifeStatusDetails: (
        <LifeStatusDetails
          key="lifeStatusDetails"
          lifeStatusPath={lifeStatusPath}
          handleChange={handleChange}
          label={getLabel(localization, CAUSE_OF_DEATH)}
        />
      ),
      lifeStatusAge: (
        <LifeStatusAge
          key="lifeStatusAge"
          lifeStatusPath={lifeStatusPath}
          handleChange={handleChange}
          label={getLabel(localization, AGE_OF_DEATH)}
        />
      ),
      postMortemReport: (
        <PostMortemReport
          key="postMortemReport"
          lifeStatusPath={lifeStatusPath}
          handleChange={handleChange}
          localization={localization}
        />
      ),
    },
    config,
  })
}

export default DeathDetails
