/**
 * Checks if person with id exists
 * @type {PersonReducer}
 */
export const getPerson = (state, action) => {
  const { id } = action.payload
  const person = state.persons[typeof id === 'function' ? id(state) : id]

  if (!person) {
    throw new Error('Unable to find the person.')
  }

  return person
}

/**
 * Set/add object with specified type in list
 * ie. person.properties: [{ type: string, isPresent: string }]
 * @param {WritableDraft<{}>} state
 * @param { payload: { id: string, type: string, value: string }} action
 * @param {string} propertyType
 * @param {string} listType
 */
export const setPersonListObjectType = (state, action, targetObj, parentObj, newValue) => {
  const person = getPerson(state, action)
  const list = person[parentObj]

  if (!list) {
    // create new list and new object
    person[parentObj] = [newValue]

    return
  }

  const index = list.findIndex(({ type }) => type === targetObj)

  if (index < 0) {
    // create new object in existing list
    person[parentObj] = [...list, newValue]
  } else {
    // modify existing object in list
    list[index] = newValue
  }
}

/**
 * Set/Add object properties in a list
 * ie. person.diagnosticTests: [{ type: string }]
 * @param {WritableDraft<{}>} state
 * @param { payload: { id: string, type: string, value: string }} action
 * @param {string} propertyType
 * @param {string} listType
 */
export const setPersonListObjectProperty = (state, action, targetObj, parentObj) => {
  const { type, value } = action.payload
  const person = getPerson(state, action)
  const list = person[parentObj]

  if (!list) {
    // create new list and new object
    person[parentObj] = [{ [type]: value }]

    return
  }

  const index = list.findIndex(({ type }) => type === targetObj)

  if (index < 0) {
    // create new object in existing list
    person[parentObj] = [...list, { [type]: value }]
  } else {
    if (value || value === 0) {
      // modify existing object in list
      list[index] = {
        ...list[index],
        [type]: value,
      }
    } else {
      if (value === '') {
        delete list[index][type]
      }
    }
  }
}

/**
 * Remove an object property from a list
 * ie. person.diagnosticTests: [{ isPerformed: 'Y' }]
 * @param {WritableDraft<{}>} state
 * @param { payload: { id: string, type: string, value: string }} action
 * @param {string} propertyType
 * @param {string} listType
 */
export const removePersonListObjectProperty = (state, action, propertyType, listType) => {
  const person = getPerson(state, action)
  const index = person[listType].findIndex(({ type }) => type === propertyType)

  if (index < 0) {
    throw new Error('Unable to find the type.')
  }

  delete person[listType][index][action.payload.type]
}

/**
 * Updates an object property at the 1st level (ie. geneticTesting: { performed: 'Y' })
 * @param {WritableDraft<{}>} state redux state
 * @param {PersonAction} action redux action
 * @param {string | null} property specific property
 */
export const setPersonObjectProperty = (state, action, property) => {
  const { type, value } = action.payload
  const person = getPerson(state, action)
  const personProperty = person[property]

  if (person[property]) {
    if (value === undefined || value === '' || (typeof value === 'number' && isNaN(value))) {
      delete person[property][type]

      return
    }

    person[property] = { ...personProperty, [type]: value }
  } else {
    person[property] = {}
    person[property][type] = value
  }
}

/**
 * Removes an object property at the 1st level (ie. geneticTesting: { performed: 'Y' })
 * @param {WritableDraft<{}>} state redux state
 * @param {PersonAction} action redux action
 * @param {string | null} property specific property
 */
export const removePersonObjectProperty = (state, action, property) => {
  const { type } = action.payload
  const person = getPerson(state, action)
  const personProperty = person[property]

  if (!personProperty) {
    throw new Error('unable to find the person property.')
  }

  delete person[property][type]
}
