function setRespondentProperty({ state, property, value }) {
  const { respondent = {} } = state

  state.respondent = {
    ...respondent,
    [property]: value,
  }
}

export default {
  /**
   * Set respondent person
   * @type {(state: WritableDraft<{}>, action: { payload: { value: string }}) => WritableDraft}
   */
  setRespondentPerson(state, action) {
    const { value } = action.payload
    const { respondent = {} } = state
    // Add new person
    if (!respondent?.person) {
      setRespondentProperty({ state, property: 'person', value })

      return
    }

    // edit existing person
    state.respondent = {
      ...respondent,
      person: {
        ...respondent.person,
        ...value,
      },
    }
  },
  /**
   * Remove respondent.person
   * @type {(state: WritableDraft<{}>, action) => WritableDraft}
   */
  removeRespondentPerson(state) {
    state.respondent.person = null
  },
  /**
   * Set respondent user
   * @type {(state: WritableDraft<{}>, action: { payload: { value: string }}) => WritableDraft}
   */
  setRespondentUser(state, action) {
    setRespondentProperty({ state, property: 'user', value: action.payload.value })
  },
  /**
   * Set respondent notes
   * @type {(state: WritableDraft<{}>, action: { payload: { value: string }}) => WritableDraft}
   */
  setRespondentNotes(state, action) {
    setRespondentProperty({ state, property: 'notes', value: action.payload.value })
  },
  /**
   * Set respondent isFamilyMember
   * @type {(state: WritableDraft<{}>, action: { payload: {
   *  value: ('N'|'Y'|'U')
   * }}) => WritableDraft}
   */
  setRespondentIsFamilyMember(state, action) {
    setRespondentProperty({ state, property: 'isFamilyMember', value: action.payload.value })
  },
  /**
   * Remove respondent.isFamilyMember
   * @type {(state: WritableDraft<{}>, action) => WritableDraft}
   */
  removeRespondentIsFamilyMember(state) {
    delete state.respondent.isFamilyMember
  },
}
