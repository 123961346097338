import { get, values } from 'lodash'
import { useShallowSelector } from '../redux/reduxHooks'
import FamilyHelper from '../utils/FamilyHelper'

export const useFamilyHelperHook = ({ id }) => {
  const persons = useShallowSelector((state) => get(state, ['questionnaire', 'persons'])) || {}
  const relationships =
    useShallowSelector((state) => get(state, ['questionnaire', 'relationships'])) || []

  /**
   * Returns a list of sibling person objects
   * @returns {Array<string>} The current or updated input value.
   */
  function getSiblings() {
    const siblingPersons = values(new FamilyHelper({ persons, relationships }).getSiblings(id))

    return siblingPersons.map((item) => item.person)
  }
  /**
   * Returns a list of sibling ids
   * @returns {Array<string>} The current or updated input value.
   */
  function getSiblingIds() {
    const siblings = getSiblings() || []

    return siblings.map((person) => person.id)
  }

  return { persons, relationships, getSiblingIds, getSiblings }
}
